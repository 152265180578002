import React, { useEffect, useState } from "react";
import * as Type from "./types";
import * as S from "./styles";
import ConcashByBtg from "../assets/concashlogo.svg"
import ConcashBtg from "../assets/concashBTG.svg"
import HorizontalLine from "../assets/horizontalLine.svg"
import HorizontalLineMobile from "../assets/horizontalLineMobile.svg"
import Tip1 from "../assets/tip1.svg";
import Tip2 from "../assets/tip2.svg";
import P1S from "../assets/p1-parceiro.svg";
import P2S from "../assets/p2-parceiro.svg";
import P3S from "../assets/p3-parceiro.svg";
import Check from "../assets/check.svg";
import "./reset.css";
import axios from "axios";
import { DateTime } from "luxon";
import Lottie from "lottie-react";
import moment from "moment";
import checkDevice from "../../../../utils/isMobile";
import { ComoFuncionaCardDesktop, ComoFuncionaCardMobile } from "../components/comofunciona/ComoFuncionaCard";
import { IconeTextoDesktop } from "../components/iconeTexto/IconeTexto";
import api from "../../../../services/api";
import { VantagensDesktop, VantagensMobile } from "../components/Vantagens/VantagensParceiro";
import { FooterDesktop, FooterMobile } from "../components/Footer/Footer";
import { Backdrop, Checkbox, FormControlLabel, Radio, RadioGroup, TextField } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { V3TextCustom } from "../../../../styles/themes/components/input";
import { validNome } from "../../../../utils/validate";
import { v3CellphoneFormater, v3FormataCNPJ, v3MaskCPF } from "../../../../styles/themes/utils/format";
import { getNavegador, getSistemaOperacional } from "../../../../styles/themes/utils/plataforma";
import { v3ValidaCpfCnpj, v3ValidateEmail } from "../../../../styles/themes/utils/validate";
import { V3Modal } from "../../../../styles/themes/components/modal";
import { V3Button } from "../../../../styles/themes/components/button";
import StyleM from "./LP.module.css";
import TagManager from 'react-gtm-module'
import { isValidCNPJ } from "../../../../utils/validateCNPJ";

const Home: React.FC = () => {
    
  const landing: any = window.location.pathname.replace("/", "");
  const queryParams = new URLSearchParams(window.location.search);

  const [acceptTerms, setAcceptTerms] = useState(false);
  const [loading, setLoading] = useState(false);
  const mobile = window.matchMedia("(max-width:768px)").matches;
  const [estilo, setEstilo] = useState<Type.EstiloProps | null>(null);
  const [cookies, setCookies] = useState(true);
  const [iconLoading, setIconLoading] = useState<any>()
  const [formSubmetido, setFormSubmetido] = useState(false);

  const [nomeRazao, setNomeRazao] = useState("");
  const [nomeRazaoerr, setNomeRazaoerr] = useState(false);
  const [telefone, setTelefone] = useState("");
  const [telefoneerr, setTelefoneer] = useState(false);
  const [contatoWhatsapp, setContatoWhatsapp] = useState(false);
  const [cpfCnpj, setCpfCnpj] = useState("");
  const [cpfCnpjerr, setCpfCnpjerr] = useState(false);
  const [email, setEmail] = useState("");
  const [emailerr, setEmailerr] = useState(false);
  const [ isDealer, setIsDealer ] = useState<any>("");

  const [hasError, setHasError] = useState(false);
  const [errorText, setErrorText] = useState("");

  const iconP: any = { parceiro: ["", P1S, P2S, P3S] };

  const [horaAcesso, setHoraAcesso] = useState("");
  const [browserUser, setBrowserUser] = useState("");
  const [isMobileUser, setIsMobileUser] = useState(false);
  const [ipUser, setIpUser] = useState("");
  const [startForm, setStartForm] = useState("");
  const [hrAceite, setHrAceite] = useState("");
  const [os, setOS] = useState("");
  const [timeZone, setTimeZone] = useState("");
  const coverMobileStyle = {
    width: "100%",
    borderRadius: "180px 180px 0px 0px",
    color: "#FFF",
  };

  const coverDesktopStyle = {
    display: "flex",
    borderRadius: "190px 190px 0px 0px",
    zIndex: 0,
  };

  function handleClear() {
    setNomeRazao("");
    setCpfCnpj("");
    setTelefone("");
    setContatoWhatsapp(false);
    setEmail("");
  }

  function handleNomeRazao(text: any) {
    const valid: any = validNome(text.target.value);
    handleGetDataAccess();
    if (!text.target.value || !valid) {
      setNomeRazao(text.target.value);
      setNomeRazaoerr(true);
    } else {
      setNomeRazao(text.target.value);
      setNomeRazaoerr(false);
    }
  }

  const handleGetStyles = async () => {
    if (estilo) {
      return;
    } else {
      try {
        const { data } = await api.get( `api/dado-tela/1.0/buscar/0` );
        setEstilo(data);
        localStorage.setItem("administradoraO", "0");
        localStorage.setItem("channel", "22");
      } catch (error) {
        console.log(error);
      }
    }
  };

  function horaAcessado() {
    if(horaAcesso) {
      return;
    }
    const acessado = moment([], true).format("DD/MM/YYYY  HH:mm:ss");
    setHoraAcesso(acessado);
  }

  function getNavigationData() {
    setOS(getSistemaOperacional(window));
    setBrowserUser(getNavegador(window));
    setIsMobileUser(checkDevice());
  }

  async function handleGetIP() {
    if(ipUser) return;
    await axios
      .get("https://api.ipify.org?format=json")
      .then(({data}: any) => {
        setIpUser(data.ip);
      })
      .catch((error: any) => console.log(error));
  }

  function handleTelefone(t: any) {
    const validatePhone = t.replace(/[^0-9]/g, "").split("");
    if (t) {
      setTelefoneer(false);
      if (t.length > 15) {
        setTelefone(telefone);
      } else {
        setTelefone(v3CellphoneFormater(t));
      }
    }
    if (!t || t == "" || t == "(") {
      setTelefoneer(true);
      setTelefone(t);
    }

    if (t.length < 14 || validatePhone[0] === "0") {
      setTelefoneer(true);
    } else {
      setTelefoneer(false);
    }
  }

  function handleEmail(t: any) {
    const text = t.replaceAll(" ", "");
    if (v3ValidateEmail(text) || text === "") {
      setEmail(text);
      setEmailerr(false);
    } else {
      setEmail(text);
      setEmailerr(true);
    }
  }

  function handleCpfCnpj(text: string) {
    const validating = text.replace(/[^\d]/g, "");

    if (validating.length <= 14) {
      if (validating.length <= 11) {
        setCpfCnpj(validating)
        return setCpfCnpjerr(true);
      } else {
        const cn = v3FormataCNPJ(validating);
        setCpfCnpj(cn);

        if (v3ValidaCpfCnpj(cn) || text === "") {
          setCpfCnpjerr(false);
        } else {
          setCpfCnpjerr(true);
        }
      }
    }
  }
  function handleAccept() {
    if (acceptTerms) {
      setAcceptTerms(acceptTerms);
    } else {
      const acessado = moment([], true).format("DD/MM/YYYY  HH:mm:ss");
      setHrAceite(acessado);
      setAcceptTerms(true);
      return acessado;
    }
  }

  function handleGetDataAccess() {
    if (startForm) {
      return;
    }
    const acessado = moment([], true).format("DD/MM/YYYY  HH:mm:ss");
    setStartForm(acessado);
    const tz = DateTime.local().zone.name;
    setTimeZone(tz);
  }

    async function handleSendFormulario() {
        const aceito = handleAccept();
        if (!nomeRazao || nomeRazaoerr) {
            setErrorText("Preencha corretamente a razão social.");
            return setHasError(true);
        }

        if (!cpfCnpj || cpfCnpjerr) {
            setErrorText("Preencha corretamente o CNPJ.");
            return setHasError(true);
        }

        if (!telefone || telefoneerr) {
            setErrorText("Preencha corretamente o número de telefone.");
            return setHasError(true);
        }
        if (!email || emailerr) {
            setErrorText("Preencha corretamente o E-Mail.");
            return setHasError(true);
        }

        const cpfcnpjParsed = parseInt(cpfCnpj.replace(/[^0-9]/g, ""));
        const tels: any = telefone?.split(" ");
        const ddd = tels[0]?.replace(/[^0-9]/g, "");
        const tel = tels[1]?.replace(/[^0-9]/g, "");
    
        const plataforma = `{
            "acessado_em": "${horaAcesso}", 
            "mobile": ${isMobileUser ? "SIM" : "NÃO"},
            "navegador": "${browserUser}", 
            "os": "${os}", 
            "ip": "${ipUser}", 
            "aceite_politica_em": "${hrAceite ? hrAceite : aceito}", 
            "inicio_form":" ${startForm}", 
            "timezone": "${timeZone}"    
        }`;

        const query = new URLSearchParams(window.location.search);
    
        var source = query.get('utm_source')?.toString()
        var medium = query.get('utm_medium')?.toString()
        var campaign = query.get('utm_campaign')?.toString()
        var term = query.get('utm_term')?.toString()
        var content = query.get('utm_content')?.toString()

        if(isDealer === "") {
          setHasError(true);
          return setErrorText("Selecione se você é revendedor ou está vendendo sua cota.");
        }

        if(isDealer === "REVENDEDOR") {
          setLoading(true);
          const valid = isValidCNPJ(String(cpfcnpjParsed));
          if(!valid) {
            setLoading(false);
            setHasError(true);
            return setErrorText("Forneça um CNPJ válido!");
          }
          await api.post("/api/site/1.0/cadastro-parceiro", {
            nomeRazao: nomeRazao,
            cpfCnpj: cpfcnpjParsed,
            telefone: tel,
            email: email,
            dddTelefone: ddd,
            contatoWhatsapp: contatoWhatsapp ? "1" : "0",
            plataforma: plataforma,
            ip: `${ipUser}`,
            idCanal: 22,
            source: source,
            medium: medium,
            campaign: campaign,
            term: term,
            content: content,

        }).then((response: any) => {
            handleClear();
            setLoading(false);
            setFormSubmetido(true);
            const tagManagerArgs = {
                gtmId: 'GTM-WB757J7'
            }
            TagManager.initialize(tagManagerArgs);
            (window as any).dataLayer.push({
                event: "solicitacao-parceiro",
                email: email,
                phone: ddd+'-'+tel,
            });
        }).catch((err: any) => {
            setLoading(false);
            setErrorText("Houve um erro ao enviar os dados.");
            return setHasError(true);
        });
        }


    }

    useEffect(() => {

        handleGetStyles();
        horaAcessado();
        handleGetIP();
        getNavigationData();
    }, []);

    function handleGoToVC() {
      window.location.href = queryParams.size != 0 ? "/venderconsorcio" + "?" + queryParams : "/venderconsorcio"
    }

    useEffect(() => {
      const interval = setInterval(() => {
        const fmdWhatsappTags = document.getElementsByTagName('fmd-whatsapp');
  
        if (fmdWhatsappTags.length > 0) {
          Array.from(fmdWhatsappTags).forEach((tag) => {
            tag.parentNode?.removeChild(tag);
          });
        }
      }, 1000);
  
      return () => {
        clearInterval(interval);
      };
    }, []);
  return estilo ? (
    <>
      <Helmet prioritizeSeoTags>
        <title>Parceiros Concash</title>
        <link rel="canonical" href={`https://concash.com.br/${landing}`} />
        <meta name="description" content={ "Tem clientes com cotas de consórcio canceladas? Seja um parceiro Concash | BTG Pactual e expanda suas oportunidades financeiras, com a melhor remuneração do mercado." } />
      </Helmet>

      <V3Modal open={hasError} actionClose={setHasError} title={"Atenção!"} estilo={landing} text={errorText} type="error" />
      <V3Modal open={isDealer === "NAO-REVENDEDOR"} actionClose={handleGoToVC} textClose={"Vamos lá!"} title={"Olá,"} estilo={landing} text={"Para uma melhor experiência estamos direcionando você para a área de consorciados. É rapidinho."} type="success" />

      {mobile && (
        <>
          {loading && (
            <Backdrop open={loading} style={{ display: "flex", maxWidth: "fit-content", zIndex: 999 }} >
                <Lottie animationData={iconLoading} width={"20%"} />
            </Backdrop>
          )}
          {cookies && (
            <Backdrop
              style={{ zIndex: 1000 }}
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={cookies}
            >
              <S.CookieBoxMobile style={{ maxWidth: "fit-content", }} >
                <S.TextFooterMobile>
                    Nós armazenamos dados temporariamente para melhorar a sua experiência de navegação e recomendar conteúdo do seu 
                    interesse. Ao continuar navegando, você concorda com a nossa política de privacidade.
                </S.TextFooterMobile>

                <S.ButtonCookie style={{ backgroundColor: estilo?.backgroundColor, marginBottom: 10, }} onClick={() => setCookies(false)} >
                    CONTINUAR
                </S.ButtonCookie>
              </S.CookieBoxMobile>
            </Backdrop>
          )}
          <>
            <S.MobilePageContainer>
                <S.MobileHeader style={{ backgroundColor: estilo?.backgroundColor }} >
                    <S.MobileHeaderTopBox>
                        <img loading="lazy" src={ConcashByBtg} alt="concash" />
                    </S.MobileHeaderTopBox>
                    <S.MobileFilterPhoto style={{ backgroundColor: estilo?.backgroundColor }} />
                    <S.MobilePhotoBox style={coverMobileStyle}>
                        <img
                            src={ formSubmetido ? estilo?.backgroundCoverSuccess : estilo?.backgroundCover }
                            alt={ estilo?.altBackground }
                            height="430px"
                            style={{
                                width: "100%",
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center",
                                backgroundSize: "cover",
                                borderRadius: "180px 180px 0px 0px",
                                color: "#FFF",
                                objectFit: "cover",
                            }}
                        />
                        {!formSubmetido ? (
                            <>
                                <S.MobilePhotoTitle style={{ zIndex: 5, fontSize: 24, marginBottom: 10, width: "90vw", left: 10 }} >
                                    Tem clientes com cotas de consórcio canceladas? Nós podemos te ajudar!
                                </S.MobilePhotoTitle>
                                <S.MobilePhotoButton
                                    onClick={() =>  window.location.href = "#form" }
                                    style={{ backgroundColor: estilo?.buttonColor, color: "#FFF", zIndex: 7 }}
                                >
                                    Saiba mais
                                </S.MobilePhotoButton>
                            </>
                        ) : (
                            <>
                                <S.MobilePhotoTitle style={{ zIndex: 5, marginBottom: 10, width: "90vw", left: 10 }} >
                                    Solicitação enviada com sucesso!
                                </S.MobilePhotoTitle>
                                <S.MobilePhotoSubTitle style={{ zIndex: 5, width: "90vw", left: 10, marginTop: "90px" }} >
                                    Aguarde nosso contato. Obrigado por escolher a Concash.
                                </S.MobilePhotoSubTitle>
                            </>
                        )}
                    </S.MobilePhotoBox>
                </S.MobileHeader>
            </S.MobilePageContainer>

            {!formSubmetido && (
                <S.MobilePageContent>
                    <S.MobileComoFunciona>
                        <S.MobileCFBoxTitleCol>
                            <S.MobileCFTitle style={{ color: estilo?.backgroundColor }} >
                                Seja nosso parceiro e expanda suas oportunidades financeiras
                            </S.MobileCFTitle>
                            <S.MobileSubTitle>
                                Com a parceria Concash BTG Pactual, tenha acesso a comissões atrativas, pagamento rápido e garantido após 
                                a conclusão da venda da sua cota de consórcio.
                            </S.MobileSubTitle>
                        </S.MobileCFBoxTitleCol>

                        <S.MobileCFBoxCards>
                            <ComoFuncionaCardMobile
                                estilo={estilo}
                                icon={iconP[landing][1]}
                                title="Processo seguro"
                                content={ "Tenha a facilidade de vender suas cotas de consórcio de forma segura, transparente e 100% digital através da parceria entre a Concash e o BTG Pactual." }
                                adm={landing}
                                alt="ícone de processo seguro"
                            />

                            <ComoFuncionaCardMobile
                                estilo={estilo}
                                icon={iconP[landing][2]}
                                title="Confiança e garantia"
                                content="Transmita mais confiança aos seus clientes ao oferecer um processo de venda de cotas de consórcio respaldado pela expertise da Concash e pelo renome do BTG Pactual."
                                adm={landing}
                                alt="ícone de confiança e garantia"
                            />

                            <ComoFuncionaCardMobile
                                estilo={estilo}
                                icon={iconP[landing][3]}
                                title="Agilidade e suporte"
                                content="Conte com todo suporte necessário durante o processo de venda da sua cota de consórcio. Desde a avaliação até a finalização
                                        da transação, estamos aqui para ajudar."
                                adm={landing}
                                alt="ícone de agilidade e suporte"
                            />
                        </S.MobileCFBoxCards>
                    </S.MobileComoFunciona>

                <>
                    <div
                        id="form"
                        style={{
                            display: "flex",
                            flex: 1,
                            flexDirection: "column",
                            gap: 15,
                            margin: "15px",
                            padding: "15px",
                            alignContent: "center",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <h2
                            className="title-vantagens-mobile"
                            style={{
                                display: "flex",
                                marginBottom: 20,
                                color: estilo.backgroundColor,
                                wordBreak: "break-word",
                                width: 300,
                            }}
                        >
                            Preencha e seja um parceiro Concash
                        </h2>
                        <>          <div style={{ display: "flex", alignItems: "center", justifyContent: "center"}}>
                                      <RadioGroup
                                        name="revendedor"
                                        row
                                        onChange={(e) => setIsDealer(e.target.value)}
                                      >
                                        <FormControlLabel value={"NAO-REVENDEDOR"} control={<Radio sx={{ color: estilo.buttonColor, "&.Mui-checked": { color: estilo.buttonColor } }}  />} label="Quero vender minha cota" />
                                        <FormControlLabel  value={"REVENDEDOR"} control={<Radio sx={{ color: estilo.buttonColor, "&.Mui-checked": { color: estilo.buttonColor } }}  />} label="Sou um revendedor" />
                                      </RadioGroup>
                                    </div>
                            <V3TextCustom
                                sx={{ width: "320px" }}
                                size="small"
                                onChange={handleNomeRazao}
                                label="Razão Social"
                                error={nomeRazaoerr}
                                helperText={ nomeRazaoerr && `Preencha a razão social}` }
                                required
                                value={nomeRazao}
                            />

                            <V3TextCustom
                                sx={{ width: 320 }}
                                size="small"
                                onChange={(e: any) => handleCpfCnpj(e.target.value)}
                                label={"CNPJ"}
                                error={cpfCnpjerr}
                                helperText={ cpfCnpjerr && `Preencha corretamente CNPJ` }
                                required
                                value={cpfCnpj}
                            />

                            <V3TextCustom
                                sx={{ width: 320 }}
                                size="small"
                                onChange={(e: any) => handleTelefone(e.target.value)}
                                label="Telefone"
                                error={telefoneerr}
                                helperText={ telefoneerr && `Preencha o telefone` }
                                required
                                value={telefone}
                            />

                            <div style={{ display: "flex", flex: 1, alignItems: "flex-start" }} >
                                <FormControlLabel
                                    value="sim"
                                    style={{ display: "flex", alignSelf: "left" }}
                                    control={
                                        <Checkbox
                                            checked={contatoWhatsapp}
                                            style={{ display: "flex", flex: 1, justifyContent: "flex-start", }}
                                            onChange={() => setContatoWhatsapp(!contatoWhatsapp) }
                                            value={0}
                                            name="radio-buttons"
                                            inputProps={{ "aria-label": "A" }}
                                            sx={{ color: estilo.buttonColor, "&.Mui-checked": { color: estilo.buttonColor, } }}
                                        />
                                    }
                                    label="Aceito receber contato WhatsApp."
                                />
                            </div>

                            <V3TextCustom
                                sx={{ width: 320 }}
                                size="small"
                                onChange={(e: any) => handleEmail(e.target.value)}
                                label="E-mail"
                                error={emailerr}
                                helperText={ emailerr && `Preencha o e-mail` }
                                required
                                value={email}
                            />

                            <>
                    
                                <div className={StyleM.fontInter12} style={{ display: "flex", flexWrap: "wrap", maxWidth: "100%", alignItems: "center", justifyContent: "center"}}>
                                    <span>
                                        Ao enviar este formulário você concorda com as nossas
                                    </span><br />
                                    <a target="_blank" href="https://concash-prod-files-pub.s3.sa-east-1.amazonaws.com/ConCash/Termos/Politica_Privacidade.pdf" rel="noreferrer">
                                        políticas de privacidade
                                    </a>
                                </div>

                                <V3Button onClick={handleSendFormulario} estilo="parceiro" width={300} text="QUERO ME TORNAR UM PARCEIRO"/>

                            </>
                        </>
                    </div>
                </>
                
                <img style={{ marginTop: "15px" }} loading="lazy" src={HorizontalLineMobile}  />
                
                <VantagensMobile title={ "Vantagens de vender a sua cota com a Concash" } estilo={estilo} />
                
                <img style={{ marginTop: "15px" }} loading="lazy" src={HorizontalLineMobile}  />



                <h1 className="title-vantagens-mobile"
                    style={{ display: "flex", marginTop: "20px", color: estilo.backgroundColor, wordBreak: "break-word", width: 300 }}
                >
                    Aumente seus ganhos!<br />
                    Com a Concash você tem:
                </h1>
                
                <div style={{ marginTop: "30px" }}>
                    <IconeTextoDesktop 
                        align={ "left" } 
                        content={ "Processo simplificado de avaliação e venda de cotas de consórcio." }
                        icon={Check}
                    />
                </div>

                <div style={{ marginTop: "30px" }}>
                    <IconeTextoDesktop 
                        align={ "left" } 
                        content={ "Garantia de pagamento rápido após a conclusão da transação." }
                        icon={Check}
                    />
                </div>

                <div style={{ marginTop: "30px" }}>
                    <IconeTextoDesktop 
                        align={ "left" } 
                        content={ "Assessoria especializada durante todo o processo de compra da cota de consórcio." }
                        icon={Check}
                    />
                </div>

                </S.MobilePageContent>
            )}
          </>

          <FooterMobile landing={landing} estilo={estilo} />
        </>
      )}

      {/* Desktop */}
      {!mobile && (
        <>
          {loading && (
            <Backdrop open={loading} style={{ zIndex: 999 }}>
                <Lottie animationData={iconLoading} width={"40%"} />
            </Backdrop>
          )}

          <>
            {cookies && (
              <Backdrop style={{ zIndex: 999 }} open={cookies}>
                <S.CookieBoxDesktop>
                  <S.TextFooterDesktop>
                    Nós armazenamos dados temporariamente para melhorar a sua experiência de navegação e recomendar conteúdo do seu
                    interesse. Ao continuar navegando, você concorda com a nossa política de privacidade.
                  </S.TextFooterDesktop>

                  <S.ButtonCookie style={{ backgroundColor: estilo?.backgroundColor }} onClick={() => setCookies(false)} >
                    CONTINUAR
                  </S.ButtonCookie>
                </S.CookieBoxDesktop>
              </Backdrop>
            )}
            <S.DesktopPageContainer>
                <S.DesktopHeader style={{ backgroundColor: estilo?.backgroundColor }} >

                    <S.DesktopBoxLogo>
                        <img loading="lazy" src={ConcashByBtg} height={"40px"} alt="concash" />
                    </S.DesktopBoxLogo>

                    {!formSubmetido ? (
                        <>
                            <S.DesktopBoxTitle>
                                <S.DesktopHeaderTitle>
                                    Tem clientes com cotas de consórcio canceladas? Nós podemos te ajudar!
                                </S.DesktopHeaderTitle>
                            </S.DesktopBoxTitle>

                            <S.DesktopBoxButton>
                                <S.DesktopHeaderButton onClick={ () =>  (window.location.href = "#form") }>
                                    Saiba mais
                                </S.DesktopHeaderButton>
                            </S.DesktopBoxButton>
                        </>
                    ) : (
                        <>
                            <S.DesktopBoxTitle>
                                <S.DesktopHeaderTitle>
                                    Solicitação enviada com sucesso!
                                </S.DesktopHeaderTitle>
                            </S.DesktopBoxTitle>

                            <S.DesktopBoxTitle>
                                <S.DesktopHeaderSubTitle>
                                    Aguarde nosso contato. Obrigado por escolher a Concash.
                                </S.DesktopHeaderSubTitle>
                            </S.DesktopBoxTitle>
                        </>
                    )}

                    <S.DesktopPhotoBox style={coverDesktopStyle} >
                        <img
                            src={ formSubmetido ? estilo?.backgroundCoverSuccess : estilo?.backgroundCover }
                            alt={ estilo?.altBackground }
                            style={{
                                display: "flex",
                                borderRadius: "190px 190px 0px 0px",
                                backgroundSize: "cover",
                                position: "absolute",
                                width: "400px",
                                height: "344px",
                                objectFit: "cover",
                            }}
                        />
                    </S.DesktopPhotoBox>

                    <img 
                        loading="lazy"  src={Tip1} alt="tip1"
                        style={{ position: "absolute", top: 0, right: 130, width: 116, zIndex: 0 }}
                    />

                    <img
                        loading="lazy" src={Tip2} alt="tip2"
                        style={{ position: "absolute", top: 263, right: 623, width: 116, zIndex: 0 }}
                    />
                </S.DesktopHeader>
            </S.DesktopPageContainer>

            {!formSubmetido && (
                <>
                  <S.DesktopPageContainer>
                    <S.DesktopPageContent>
                        <S.DesktopComoFunciona>
                            <S.DesktopCFBoxTitleCol>
                                <S.DesktopCFTitle style={{ color: estilo?.backgroundColor }} >
                                    Seja nosso parceiro e expanda suas oportunidades financeiras
                                </S.DesktopCFTitle>
                                <S.DesktopSubTitle style={{ textAlign: "center"}}>
                                    Com a parceria Concash BTG Pactual, tenha acesso a comissões atrativas, pagamento rápido e garantido após a 
                                    conclusão da venda da sua cota de consórcio.
                                </S.DesktopSubTitle>
                            </S.DesktopCFBoxTitleCol>
                            <S.DesktopCFBoxCards style={{ marginTop: "35px" }}>
                                <ComoFuncionaCardDesktop
                                    estilo={estilo}
                                    icon={iconP[landing][1]}
                                    title="Processo seguro"
                                    content={ "Tenha a facilidade de vender suas cotas de consórcio de forma segura, transparente e 100% digital através da parceria entre a Concash e o BTG Pactual." }
                                    adm={landing}
                                    alt="ícone de processo seguro"
                                />
                                <ComoFuncionaCardDesktop
                                    estilo={estilo}
                                    icon={iconP[landing][2]}
                                    title="Confiança e garantia"
                                    content="Transmita mais confiança aos seus clientes ao oferecer um processo de venda de cotas de consórcio respaldado pela expertise da Concash e pelo renome do BTG Pactual."
                                    adm={landing}
                                    alt="ícone de confiança e garantia"
                                />
                                <ComoFuncionaCardDesktop
                                    estilo={estilo}
                                    icon={iconP[landing][3]}
                                    title="Agilidade e suporte"
                                    content="Conte com todo suporte necessário durante o processo de venda da sua cota de consórcio. Desde a avaliação até a finalização
                                            da transação, estamos aqui para ajudar."
                                    adm={landing}
                                    alt="ícone de agilidade e suporte"
                                />
                            </S.DesktopCFBoxCards>
                        </S.DesktopComoFunciona>
                    </S.DesktopPageContent>
                  </S.DesktopPageContainer>   
                  <S.DesktopPageContainer style={{ marginTop: "35px"}}> 
                    <div style={{ display: "flex", width: "100%", justifyContent: "center", marginTop: "50px" }} >
                        <S.ContainerForm style={{ minHeight: estilo?.minHeightForm, backgroundColor: "#F5F5F5", justifyContent: "space-between" }}>
                            <div style={{ display: "flex", flex: 1, }}>
                                <div style={{ display: "flex", flexDirection: "column", width: "40%" }}>
                                    <div style={{  justifyContent: "left", marginTop: "190px" }}>
                                        <img loading="lazy" src={ estilo?.backgroundForm } alt="icon" />
                                    </div>
                                </div>
                                <div style={{ display: "flex", flex: 1, flexDirection: "column", width: "60%"  }}>
                                    <S.DesktopCFBoxTitleCol style={{ marginTop: "30px"}}>
                                        <S.DesktopCFTitle style={{ color: estilo?.backgroundColor }} >
                                            Preencha e seja um
                                        </S.DesktopCFTitle>
                                        <S.DesktopCFTitle style={{ color: estilo?.backgroundColor }} >
                                            parceiro Concash
                                        </S.DesktopCFTitle>
                                    </S.DesktopCFBoxTitleCol>
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center"}}>
                                      <RadioGroup
                                        name="revendedor"
                                        row
                                        onChange={(e) => setIsDealer(e.target.value)}
                                      >
                                        <FormControlLabel value={"NAO-REVENDEDOR"} control={<Radio sx={{ color: estilo.buttonColor, "&.Mui-checked": { color: estilo.buttonColor } }}  />} label="Quero vender minha cota" />
                                        <FormControlLabel  value={"REVENDEDOR"} control={<Radio sx={{ color: estilo.buttonColor, "&.Mui-checked": { color: estilo.buttonColor } }}  />} label="Sou um revendedor" />
                                      </RadioGroup>
                                    </div>
                                    <div
                                        id="form"
                                        style={{
                                            display: "flex",
                                            flex: 1,
                                            flexDirection: "column",
                                            gap: 15,
                                            padding: "0 100px",
                                            alignContent: "center",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                    >
                                        <V3TextCustom
                                            sx={{ width: 500 }}
                                            size="small"
                                            onChange={handleNomeRazao}
                                            label="Razão Social"
                                            error={nomeRazaoerr}
                                            helperText={ nomeRazaoerr && `Preencha a razão social` }
                                            required
                                            value={nomeRazao}
                                        />

                                        
                                        <V3TextCustom
                                            sx={{ width: 500 }}
                                            size="small"
                                            onChange={(e: any) => handleCpfCnpj(e.target.value)}
                                            label="CNPJ"
                                            error={cpfCnpjerr}
                                            helperText={ cpfCnpjerr && `Preencha corretamente o CNPJ` }
                                            required
                                            value={cpfCnpj}
                                        />

                                        <V3TextCustom
                                            sx={{ minWidth: 500 }}
                                            size="small"
                                            onChange={(e: any) => handleTelefone(e.target.value)}
                                            label="Telefone"
                                            error={telefoneerr}
                                            helperText={ telefoneerr && `Preencha o telefone` }
                                            required
                                            value={telefone}
                                        />

                                        <FormControlLabel
                                            value="sim"
                                            style={{ display: "flex", alignSelf: "left", minWidth: 220 }}
                                            control={
                                                <Checkbox
                                                    checked={contatoWhatsapp}
                                                    style={{ display: "flex", flex: 1, justifyContent: "flex-start", }}
                                                    onChange={() => setContatoWhatsapp(!contatoWhatsapp) }
                                                    value={0}
                                                    name="radio-buttons"
                                                    inputProps={{ "aria-label": "A" }}
                                                    sx={{ color: estilo.buttonColor, "&.Mui-checked": { color: estilo.buttonColor } }}
                                                />
                                            }
                                            label="Aceito receber contato WhatsApp."
                                        />

                                        <V3TextCustom
                                            sx={{ width: 500 }}
                                            size="small"
                                            onChange={(e: any) => handleEmail(e.target.value)}
                                            label="E-mail"
                                            error={emailerr}
                                            helperText={ emailerr && 'Preencha o email' }
                                            required
                                            value={email}
                                        />  

                                        <div className={StyleM.fontInter12} style={{ marginTop: 15, marginBottom: 15 }}>
                                            Ao enviar este formulário você concorda com as nossas{" "}
                                            <a target="_blank" href="https://concash-prod-files-pub.s3.sa-east-1.amazonaws.com/ConCash/Termos/Politica_Privacidade.pdf" rel="noreferrer" >
                                                políticas de privacidade
                                            </a>
                                        </div>
                                        <V3Button onClick={handleSendFormulario} estilo="parceiro" width={500} text="QUERO ME TORNAR PARCEIRO" />
                                    </div>
                                </div>
                            </div>
                        </S.ContainerForm>
                    </div>
                  </S.DesktopPageContainer>
                  <S.DesktopPageContainer>
                    <VantagensDesktop title={ "Vantagens de vender a sua cota com a Concash" } estilo={estilo} />
                  </S.DesktopPageContainer>
                  <S.DesktopPageContainer style={{ marginTop: "50px"}}>
                    <img loading="lazy" src={HorizontalLine}  />
                  </S.DesktopPageContainer>
                  <S.DesktopPageContainer>
                    <S.DesktopPageContent style={{ marginTop: "40px" }}>
                        <S.DesktopBoxLogo>

                        </S.DesktopBoxLogo>
                    </S.DesktopPageContent>
                  </S.DesktopPageContainer>
                    <S.DesktopPageContent>
                        <S.DesktopCFBoxTitleCol>
                            <S.DesktopCFTitle style={{ color: estilo?.backgroundColor }} >
                            Aumente seus ganhos!<br />
                            Com a Concash você tem:
                            </S.DesktopCFTitle>
                        </S.DesktopCFBoxTitleCol>
                    </S.DesktopPageContent>
                    <div style={{ display: "flex", flex: 1, width: "100%", justifyContent: "center" }}>
                        <S.DesktopCFBoxTitleCol>
                            <IconeTextoDesktop 
                                align={ "left" } 
                                content={ "Processo simplificado de avaliação e venda de cotas de consórcio." }
                                icon={Check}
                            />
                            <IconeTextoDesktop 
                                align={ "left" } 
                                content={ "Garantia de pagamento rápido após a conclusão da transação." }
                                icon={Check}
                            />
                            <IconeTextoDesktop 
                                align={ "left" } 
                                content={ "Assessoria especializada durante todo o processo de compra da cota de consórcio." }
                                icon={Check}
                            />
                        </S.DesktopCFBoxTitleCol>
                    </div>
                </>
            )}

            <FooterDesktop landing={landing} estilo={estilo} />
            </>
        </>
      )}
    </>
  ) : (
    <></>
  );
};

export default Home;