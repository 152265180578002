import styled from "styled-components";
import { colors } from "../../../styles/colors";
import { ScreenDisplay } from "../../../styles/display";

export const FooterContainerDesktop = styled.div`
  display: flex;
  flex: 1;
  background-color: #fff;
  height: 120px;
  position: relative;
  top: -200px;

  @media (${ScreenDisplay.desktop}) {
    margin-top: 40px;
    padding: 0px 150px;
    justify-content: center;
  }

  .footer-center {
    display: flex;
    justify-content: center;
    justify-items: center;
    width: 800px;
    height: 361px;
    border-top: 1px solid #ddd;
    gap: 30px;
  }

  .col-footer {
    display: flex;
    flex: 3;
    flex-direction: column;
    padding: 40px 0px;
    gap: 13px;
    height: fit-content;
  }
  .col-footer2 {
    display: flex;
    flex: 3;
    flex-direction: column;
    padding: 40px 0px;
    gap: 5px;
    height: fit-content;
  }
  .circle {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: #16898e;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    text-decoration: none;
  }

  .contact-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .text-contact {
    text-align: left;
    font: normal normal normal 15px/27px Roboto;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    line-height: 1;
  }

  .subtext {
    font: normal normal normal 12px Roboto;
    letter-spacing: 0px;
    color: #898989;
    opacity: 1;
    line-height: 1;
  }

  .img-fluida {
    width: calc(100vw / 10);
  }

  .link-list-desktop {
    color: var(--unnamed-color-16898e);
    text-align: left;
    text-decoration: none;
    font: normal normal bold 15px/30px Roboto;
    letter-spacing: 0px;
    color: #16898e;
    opacity: 1;
  }
`;

export const FooterContainerMobile = styled.div`
  display: flex;
  flex: 1;
  background-color: #fff;
  height: 545px;
  position: relative;
  top: -180px;

  .footer-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    justify-items: center;
    gap: 10px;
    height: 730px;
  }

  .col-footer {
    display: flex;
    flex-direction: column;
    padding: 10px 0px;
    gap: 13px;
  }
  .col-footer-center {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 10px 0px;
    gap: 13px;
  }

  .col-footer2 {
    display: flex;
    flex-direction: column;
    padding: 20px 89px;
    gap: 13px;
  }
  .circle {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: #16898e;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    text-decoration: none;
  }

  .contact-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .text-contact {
    text-align: left;
    font: normal normal normal 15px/27px Roboto;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    line-height: 1;
  }

  .text-contact-center {
    text-align: center;
    font: normal normal normal 15px/27px Roboto;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    line-height: 1;
  }

  .subtext {
    font: normal normal normal 12px Roboto;
    letter-spacing: 0px;
    color: #898989;
    opacity: 1;
    line-height: 1;
  }

  .img-fluida {
    width: calc(100vw / 10);
  }

  .link-list-mobile {
    color: var(--unnamed-color-16898e);
    text-align: center;
    text-decoration: none;
    font: normal normal bold 15px/30px Roboto;
    letter-spacing: 0px;
    color: #16898e;
    opacity: 1;
  }
`;
