import React, { useEffect, useState } from "react";
import * as Type from "./types";
import * as S from "./styles";
import ConcashMobile from "../assets/concashlogo.svg";
import Tip1 from "../assets/tip1.svg";
import Tip2 from "../assets/tip2.svg";
import P1M from "../assets/p1-cnp.png";
import P2M from "../assets/p2-cnp.png";
import P3M from "../assets/p3-cnp.png";
import "./reset.css";
import axios from "axios";
import { DateTime } from "luxon";
import Lottie from "lottie-react";
import CNPLoading from "../../../../assets/lottie/cnp-load.json";
import moment from "moment";
import checkDevice from "../../../../utils/isMobile";
import { ComoFuncionaCardDesktop, ComoFuncionaCardMobile } from "../components/comofunciona/ComoFuncionaCard";
import api from "../../../../services/api";
import { VantagensDesktop, VantagensMobile } from "../components/Vantagens/Vantagens";
import { FooterDesktop, FooterMobile } from "../components/Footer/Footer";
import { Backdrop, Checkbox, FormControlLabel } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { V3TextCustom } from "../../../../styles/themes/components/input";
import { validNome } from "../../../../utils/validate";
import { v3CellphoneFormater, v3FormataCNPJ, v3MaskCPF } from "../../../../styles/themes/utils/format";
import { v3ValidaCpfCnpj, v3ValidateEmail } from "../../../../styles/themes/utils/validate";
import { V3Modal } from "../../../../styles/themes/components/modal";
import { V3Text5 } from "../../../../styles/themes/components/texts";
import { ImInfo } from "react-icons/im";
import { V3Button } from "../../../../styles/themes/components/button";
import StyleM from "./LP.module.css";
import TagManager from 'react-gtm-module';

const Home: React.FC = () => {
  const landing: any = window.location.pathname.replace("/", "");
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tituloLanding, setTituloLanding] = useState("");
  const mobile = window.matchMedia("(max-width:768px)").matches;
  const [estilo, setEstilo] = useState<Type.EstiloProps | null>(null);
  const [cookies, setCookies] = useState(true);
  const [formProps, setFormProps] = useState<any>();
  const [extrato, setExtrato] = useState("");
  const [iconLoading, setIconLoading] = useState<any>()

  const [nomeRazao, setNomeRazao] = useState("");
  const [nomeRazaoerr, setNomeRazaoerr] = useState(false);
  const [telefone, setTelefone] = useState("");
  const [telefoneerr, setTelefoneer] = useState(false);
  const [contatoWhatsapp, setContatoWhatsapp] = useState(false);
  const [cpfCnpj, setCpfCnpj] = useState("");
  const [cpfCnpjerr, setCpfCnpjerr] = useState(false);
  const [email, setEmail] = useState("");
  const [emailerr, setEmailerr] = useState(false);
  const [grupo, setGrupo] = useState("");
  const [grupoerr, setGrupoerr] = useState(false);
  const [cota, setCota] = useState("");
  const [cotaerr, setCotaerr] = useState(false);
  const [successLinkWP, setSuccessLinkWP] = useState("");

  const [hasInfoCNP, setHasInfoCNP] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [hasSuccess, setHasSuccess] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [successText, setSuccessText] = useState("");

  const iconP: any = {
    cnp: ["", P1M, P2M, P3M],
  };

  const [horaAcesso, setHoraAcesso] = useState("");
  const [browserUser, setBrowserUser] = useState("");
  const [isMobileUser, setIsMobileUser] = useState(false);
  const [ipUser, setIpUser] = useState("");
  const [startForm, setStartForm] = useState("");
  const [hrAceite, setHrAceite] = useState("");
  const [os, setOS] = useState("");
  const [timeZone, setTimeZone] = useState("");
  const coverMobileStyle = {
    width: "100%",
    borderRadius: "180px 180px 0px 0px",
    color: "#FFF",
  };

  const coverDesktopStyle = {
    display: "flex",
    borderRadius: "190px 190px 0px 0px",
    opacity: 0.6,
    zIndex: 0,
  };

  const actions: any = {
    nomeRazao: handleChange,
    telefone: setTelefone,
    contatoWhatsapp: setContatoWhatsapp,
    cpfCnpj: setCpfCnpj,
    email: setEmail,
    grupo: setGrupo,
    cota: setCota,
  };

  const values: any = {
    nomeRazao: nomeRazao,
    telefone: setTelefone,
    contatoWhatsapp: setContatoWhatsapp,
    cpfCnpj: setCpfCnpj,
    email: setEmail,
    grupo: setGrupo,
    cota: setCota,
  };

  function handleClear() {
    setNomeRazao("");
    setCpfCnpj("");
    setTelefone("");
    setContatoWhatsapp(false);
    setEmail("");
    setGrupo("");
    setCota("");
    setExtrato("");
  }

  function handleChange(c: any, v: any) {
    if (v === "nomeRazao") {
      setNomeRazao(c);
    }
  }

  function handleNomeRazao(text: any) {
    const valid: any = validNome(text.target.value);
    handleGetDataAccess();
    if (!text.target.value || !valid) {
      setNomeRazao(text.target.value);
      setNomeRazaoerr(true);
    } else {
      setNomeRazao(text.target.value);
      setNomeRazaoerr(false);
    }
  }

  const handleGetStyles = async () => {
    if (estilo) {
      return;
    } else {
      try {
        const { data } = await api.get(`api/dado-tela/1.0/buscar/123`);
        setEstilo(data);
        localStorage.setItem("administradoraO", '123');
        localStorage.setItem("channel", "22");
      } catch (error) {
        console.log(error);
      }
    }
  };
  function horaAcessado() {
    if(horaAcesso) {
      return;
    }
    const acessado = moment([], true).format("DD/MM/YYYY  HH:mm:ss");
    setHoraAcesso(acessado);
  }

  function getNavigationData() {
    if (window.navigator.appVersion.indexOf("Win") !== -1) {
      setOS("Windows");
    }
    if (window.navigator.appVersion.indexOf("Mac") !== -1) {
      setOS("MacOS");
    }
    if (window.navigator.appVersion.indexOf("X11") !== -1) {
      setOS("UnixOS");
    }
    if (window.navigator.appVersion.indexOf("Linux") !== -1) {
      setOS("Linux");
    }

    if (window.navigator.userAgent.indexOf("Chrome") !== -1) {
      setBrowserUser("Google Chrome");
    } else if (window.navigator.userAgent.indexOf("Firefox") !== -1) {
      setBrowserUser("Mozilla Firefox");
    } else if (window.navigator.userAgent.indexOf("MSIE") !== -1) {
      setBrowserUser("Internet Explorer");
    } else if (window.navigator.userAgent.indexOf("Edge") !== -1) {
      setBrowserUser("Edge");
    } else if (window.navigator.userAgent.indexOf("Safari") !== -1) {
      setBrowserUser("Safari");
    } else if (window.navigator.userAgent.indexOf("Opera") !== -1) {
      setBrowserUser("Opera");
    }

    const isMobileDevice = checkDevice();
    setIsMobileUser(isMobileDevice);
  }

  async function handleGetIP() {
    if(ipUser) return;
    await axios
      .get("https://api.ipify.org?format=json")
      .then(({data}: any) => {
        setIpUser(data.ip);
      })
      .catch((error: any) => console.log(error));
  }
  async function handleGetForm() {
    try {
      const { data }: any = await api.get(`api/site/1.0/landing-form?idFormulario=600`);
      setFormProps(data);
    } catch (error) {
      console.log(error);
    }
  }

  async function handleSetTitle() {
    try {
        setIconLoading(CNPLoading);
        setTituloLanding("Vender Consórcio CNP | Concash");
    } catch (error) {
      console.log(error);
    }
  }

  function handleTelefone(t: any) {
    const validatePhone = t.replace(/[^0-9]/g, "").split("");
    if (t) {
      setTelefoneer(false);
      if (t.length > 15) {
        setTelefone(telefone);
      } else {
        setTelefone(v3CellphoneFormater(t));
      }
    }
    if (!t || t == "" || t == "(") {
      setTelefoneer(true);
      setTelefone(t);
    }

    if (t.length < 14 || validatePhone[0] === "0") {
      setTelefoneer(true);
    } else {
      setTelefoneer(false);
    }
  }

  function handleEmail(t: any) {
    const text = t.replaceAll(" ", "");
    if (v3ValidateEmail(text) || text === "") {
      setEmail(text);
      setEmailerr(false);
    } else {
      setEmail(text);
      setEmailerr(true);
    }
  }

  function handleCpfCnpj(text: string) {
    const validating = text.replace(/[^\d]/g, "");

    if (validating.length <= 14) {
      if (validating.length <= 11) {
        const cpfs = v3MaskCPF(validating);
        setCpfCnpj(cpfs);
        if (v3ValidaCpfCnpj(cpfs) || text === "") {
          setCpfCnpjerr(false);
        } else {
          setCpfCnpjerr(true);
        }
      } else {
        const cn = v3FormataCNPJ(validating);
        setCpfCnpj(cn);

        if (v3ValidaCpfCnpj(cn) || text === "") {
          setCpfCnpjerr(false);
        } else {
          setCpfCnpjerr(true);
        }
      }
    }
  }
  function handleAccept() {
    if (acceptTerms) {
      setAcceptTerms(acceptTerms);
    } else {
      const acessado = moment([], true).format("DD/MM/YYYY  HH:mm:ss");
      setHrAceite(acessado);
      setAcceptTerms(true);
      return acessado;
    }
  }

  function handleGetDataAccess() {
    if (startForm) {
      return;
    }
    const acessado = moment([], true).format("DD/MM/YYYY  HH:mm:ss");
    setStartForm(acessado);
    const tz = DateTime.local().zone.name;
    setTimeZone(tz);
  }

  async function handleSendCNP() {
    const aceito = handleAccept();
    const formData = new FormData();
    if (!nomeRazao || nomeRazaoerr) {
      setErrorText("Preencha corretamente Nome / Razão social.");
      return setHasError(true);
    }
    if (!cpfCnpj || cpfCnpjerr) {
      setErrorText("Preencha corretamente o CPF ou CNPJ.");
      return setHasError(true);
    }

    if (!telefone || telefoneerr) {
      setErrorText("Preencha corretamente o número de telefone.");
      return setHasError(true);
    }
    if (!email || emailerr) {
      setErrorText("Preencha corretamente o E-Mail.");
      return setHasError(true);
    }

    if (!grupo || !cota) {
        setErrorText("Preencha corretamente grupo e cota.");
        return setHasError(true);
    }

    setLoading(true);
    const cpfcnpjParsed = parseInt(cpfCnpj.replace(/[^0-9]/g, ""));
    const tels: any = telefone?.split(" ");
    const ddd = tels[0]?.replace(/[^0-9]/g, "");
    const tel = tels[1]?.replace(/[^0-9]/g, "");

    formData.append("cpfCnpj", `${cpfcnpjParsed}`);
    formData.append("nomeRazao", nomeRazao);
    formData.append("email", email);
    formData.append("contatoWhatsapp", contatoWhatsapp ? "1" : "0");
    formData.append("dddTelefone", ddd);
    formData.append("telefone", tel);
    formData.append("idAdministradora", "123");
    formData.append("idCnal", "22");
    formData.append("idTipoConsorcio", "41");
    formData.append("comoConheceuId", "0");
    formData.append("grupo", grupo);
    formData.append("cota", cota);
    const query = new URLSearchParams(window.location.search);
    
    var origem = query.get('utm_source')?.toString()
    formData.append("origem",(origem===undefined?'':origem))

    var midia = query.get('utm_medium')?.toString()
    formData.append("midia",(midia===undefined?'':midia))

    var campanha = query.get('utm_campaign')?.toString()
    formData.append("campanha",(campanha===undefined?'':campanha))

    var segmento = query.get('utm_term')?.toString()
    formData.append("segmento",(segmento===undefined?'':segmento))

    var anuncio = query.get('utm_content')?.toString()
    formData.append("anuncio",(anuncio===undefined?'':anuncio))
    
    const plataforma = `{
      "acessado_em": "${horaAcesso}", 
      "mobile": ${isMobileUser ? "SIM" : "NÃO"},
      "navegador": "${browserUser}", 
      "os": "${os}", 
      "ip": "${ipUser}", 
      "aceite_politica_em": "${hrAceite ? hrAceite : aceito}", 
      "inicio_form":" ${startForm}", 
      "timezone": "${timeZone}"    
  }`;
  formData.append("plataforma", plataforma);
    try {
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      await api
        .post("/api/site/1.0/melhor-proposta-website", formData)
        .then(({ data }: any) => {
            const tagManagerArgs = {
                gtmId: 'GTM-WB757J7'
            }
            TagManager.initialize(tagManagerArgs);
            (window as any).dataLayer.push({
              event: "solicitacao-proposta",
              email: email,
              phone: tel
            });  
            handleClear();
            if(data?.redirectUri) {
              setSuccessText("Agora você será redirecionado ao PORTAL DO CLIENTE.");
              setSuccessLinkWP(data.redirectUri);
              setLoading(false);
              return setHasSuccess(true);
            } else {
              setSuccessText("Pode aguardar que um de nossos consultores entrará em contato através de ligação ou WhatsApp no número (11) 4040-5686.");
              setLoading(false);
              return setHasSuccess(true);
            }            
        });
    } catch (e: any) {
      setLoading(false);
      setErrorText("Houve um erro ao enviar seus dados.");
      return setHasError(true);
    }
  }

  function handleSuccess(s: any) {
    setHasSuccess(s);
    if (successLinkWP) {
        window.location.href = successLinkWP;
    } else {
        window.location.reload();
    }
  }

  useEffect(() => {
    handleSetTitle();
    handleGetStyles();
    handleGetForm();
    horaAcessado();
    handleGetIP();
    getNavigationData();
  }, []);

  useEffect(() => {
    console.log(extrato)
  }, [extrato])

  return estilo ? (
    <>
      <Helmet prioritizeSeoTags>
        <title>{tituloLanding}</title>
        <link rel="canonical" href={`https://concash.com.br/cnp`} />
        <meta
          name="description"
          content={
            "Venda seu consórcio na Concash com a melhor negociação e receba seu dinheiro à vista, no prazo mais rápido do mercado. Solicite sua proposta."
          }
        />
      </Helmet>
      <V3Modal
        open={hasInfoCNP}
        actionClose={setHasInfoCNP}
        title={"Informação sobre seu consórcio"}
        estilo="cnp"
        html={
          <V3Text5 style={{ textAlign: "center" }}>
            <img src="https://concash-hml-files-pub.s3.us-east-2.amazonaws.com/677caixa_sr_marco_3+(1).png"></img>
            <br />
            Você pode obter <b>grupo e cota</b> diretamente no seu extrato, no canto superior esquerdo!
            <br />
            <a href="https://arealogada.cnpconsorcio.com.br/login">
              Clique aqui
            </a>{" "}
            e recupere seu extrato na área logada.
          </V3Text5>
        }
      />
      <V3Modal
        open={hasError}
        actionClose={setHasError}
        title={"Atenção!"}
        estilo={landing}
        text={errorText}
        type="error"
      />
      <V3Modal
        open={hasSuccess}
        actionClose={handleSuccess}
        title={"Seus dados foram enviados com sucesso!"}
        text={successText}
        textClose="OK"
        colorIconOK={estilo.backgroundColor}
        type="success"
        estilo={landing}
      />

      {/* Mobile */}
      {mobile && (
        <>
          {loading && (
            <Backdrop
              open={loading}
              style={{ display: "flex", maxWidth: "fit-content", zIndex: 999 }}
            >
              <Lottie animationData={iconLoading} width={"20%"} />
            </Backdrop>
          )}
          {cookies && (
            <Backdrop
              style={{ zIndex: 1000 }}
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={cookies}
            >
              <S.CookieBoxMobile style={{ maxWidth: "fit-content" }} >
                <S.TextFooterMobile>
                    Nós armazenamos dados temporariamente para melhorar a sua experiência de navegação e recomendar conteúdo 
                    do seu interesse. Ao continuar navegando, você concorda com a nossa política de privacidade.
                </S.TextFooterMobile>

                <S.ButtonCookie
                  style={{ backgroundColor: estilo?.backgroundColor, marginBottom: 10 }}
                  onClick={() => setCookies(false)}
                >
                    CONTINUAR
                </S.ButtonCookie>
              </S.CookieBoxMobile>
            </Backdrop>
          )}
          <>
            <S.MobilePageContainer>
              <S.MobileHeader style={{ backgroundColor: estilo?.backgroundColor }} >
                <S.MobileHeaderTopBox>
                  <img
                    loading="lazy"
                    src={estilo?.adminLogo}
                    width={"152px"}
                    alt="adm"
                  />
                  <img loading="lazy" src={ConcashMobile} alt="concash" />
                </S.MobileHeaderTopBox>
                <S.MobileFilterPhoto style={{ backgroundColor: estilo?.backgroundColor }}/>
                <S.MobilePhotoBox style={coverMobileStyle}>
                    <img
                        src={ estilo?.backgroundCover }
                        alt={ estilo?.altBackground }
                        height="430px"
                        style={{
                            width: "100%",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                            backgroundSize: "cover",
                            borderRadius: "180px 180px 0px 0px",
                            color: "#FFF",
                            objectFit: "cover",
                        }}
                    />
                  <>
                    <S.MobilePhotoTitleCNP style={{ zIndex: 5 }}>Venda seu consórcio CNP Seguradora com a melhor oferta do mercado!</S.MobilePhotoTitleCNP>
                    <S.MobilePhotoButton
                        onClick={() => (window.location.href = "#form")}
                        style={{
                            backgroundColor: estilo?.buttonColor,
                            color: estilo?.buttonTextColor,
                            zIndex: 7,
                            marginRight: "50%",
                        }}
                    >
                        QUERO VENDER
                    </S.MobilePhotoButton>
                      <S.MobilePhotoButton
                        onClick={() => window.open("https://cliente.hml.concash.com.br/autoservico/cnp", "_blank") }
                        style={{
                          backgroundColor: estilo?.buttonColor,
                          color: estilo?.buttonTextColor,
                          zIndex: 7,
                          marginLeft: "50%",
                        }}
                      >
                        CONSULTAR PROPOSTAS
                      </S.MobilePhotoButton>
                    </>
                </S.MobilePhotoBox>
              </S.MobileHeader>
            </S.MobilePageContainer>

            <S.MobilePageContent>
                <S.MobileComoFunciona>
                    <S.MobileCFBoxTitle>
                        <S.DesktopCFTitle  style={{ color: estilo?.backgroundColor }} >
                            Veja como funciona: é 100% digital, rápido e seguro
                        </S.DesktopCFTitle>
                    </S.MobileCFBoxTitle>

                    <S.MobileCFBoxCards>
                        <ComoFuncionaCardMobile
                        estilo={estilo}
                        icon={iconP[landing][1]}
                        title="1. Preencha o formulário"
                        content={"Você envia as informações do seu consórcio através de um formulário aqui no site."}
                        adm={landing}
                        alt="ícone de preenchimento de formulário"
                        />
                        <ComoFuncionaCardMobile
                        estilo={estilo}
                        icon={iconP[landing][2]}
                        title="2. Envie a documentação"
                        content="Envie a documentação e assine o contrato. Todo processo é 100% digital e com todo suporte da nossa equipe de especialistas."
                        adm={landing}
                        alt="ícone de envio de documentação"
                        />
                        <ComoFuncionaCardMobile
                        estilo={estilo}
                        icon={iconP[landing][3]}
                        title="3. Dinheiro nas suas mãos"
                        content="Com a assinatura do contrato, basta aguardar a transferência de titularidade e o dinheiro é depositado direto na sua conta."
                        adm={landing}
                        alt="ícone de dinheiro nas suas mãos"
                        />
                    </S.MobileCFBoxCards>
                </S.MobileComoFunciona>

                <div
                    id="form"
                    style={{
                        display: "flex",
                        flex: 1,
                        flexDirection: "column",
                        gap: 15,
                        margin: "15px",
                        padding: "15px",
                        alignContent: "center",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <h2
                        className="title-vantagens-mobile"
                        style={{
                        display: "flex",
                        marginBottom: 20,
                        color: "#002364",
                        wordBreak: "break-word",
                        width: 300,
                        }}
                    >
                        {formProps?.formName}
                    </h2>
                    <>
                        <div>
                            <V3TextCustom
                                id={formProps?.fields[0]?.name}
                                sx={{ width: "320px" }}
                                size="small"
                                onChange={handleNomeRazao}
                                label={formProps?.fields[0]?.name}
                                error={nomeRazaoerr}
                                helperText={ nomeRazaoerr && `Preencha ${formProps?.fields[0]?.name}` }
                                required
                                value={nomeRazao}
                            />
                        </div>
                        <V3TextCustom
                            id={formProps?.fields[3]?.name}
                            sx={{ width: 320 }}
                            size="small"
                            onChange={(e: any) => handleCpfCnpj(e.target.value)}
                            label={formProps?.fields[3]?.name}
                            error={cpfCnpjerr}
                            helperText={ cpfCnpjerr && `Preencha corretamente ${formProps?.fields[3]?.name}` }
                            required
                            value={cpfCnpj}
                        />
                        <V3TextCustom
                            id={formProps?.fields[1]?.name}
                            sx={{ width: 320 }}
                            size="small"
                            onChange={(e: any) => handleTelefone(e.target.value)}
                            label={formProps?.fields[1]?.name}
                            error={telefoneerr}
                            helperText={ telefoneerr && `Preencha ${formProps?.fields[1]?.name}` }
                            required
                            value={telefone}
                        />

                        {telefone && (
                            <div style={{ display: "flex", flex: 1, alignItems: "flex-start" }} >
                                <FormControlLabel
                                    value="sim"
                                    style={{ display: "flex", alignSelf: "left" }}
                                    control={
                                        <Checkbox
                                        checked={contatoWhatsapp}
                                        style={{
                                            display: "flex",
                                            flex: 1,
                                            justifyContent: "flex-start",
                                        }}
                                        onChange={() =>
                                            setContatoWhatsapp(!contatoWhatsapp)
                                        }
                                        value={0}
                                        name="radio-buttons"
                                        inputProps={{ "aria-label": "A" }}
                                        sx={{
                                            color: estilo.buttonColor,
                                            "&.Mui-checked": {
                                            color: estilo.buttonColor,
                                            },
                                        }}
                                        />
                                    }
                                    label={formProps?.fields[2]?.name}
                                />
                            </div>
                        )}
                        <V3TextCustom
                            id={formProps?.fields[4]?.name}
                            sx={{ width: 320 }}
                            size="small"
                            onChange={(e: any) => handleEmail(e.target.value)}
                            label={formProps?.fields[4]?.name}
                            error={emailerr}
                            helperText={ emailerr && `Preencha ${formProps?.fields[4]?.name}` }
                            required
                            value={email}
                        />

                        <div style={{ display: "flex", flex: 1, alignItems: "center", justifyContent: "center", gap: 10 }} >
                            <V3TextCustom
                                label="Grupo"
                                style={{ minWidth: 125 }}
                                onChange={(e) => {
                                    const text = e.target.value;
                                   
                                    setGrupo(text);
                                  
                                }}
                                size="small"
                                value={grupo}
                                error={grupoerr}
                            />
                            <V3TextCustom
                                label="Cota"
                                style={{ minWidth: 125 }}
                                onChange={(e) => {
                                    const text = e.target.value.replace(/[^\d]/g, "");
                                    setCota(text);
                                  
                                }}
                                size="small"
                                value={cota}
                                error={cotaerr}
                            />

                            <div style={{ position: "relative", right: "-6px" }}>
                                <ImInfo onClick={() => setHasInfoCNP(true)} style={{ cursor: "pointer" }} />
                            </div>
                        </div>
                        <div className={StyleM.fontInter12}>
                            Ao enviar este formulário você concorda com as nossas{" "}
                            <a
                                target="_blank"
                                href="https://concash-prod-files-pub.s3.sa-east-1.amazonaws.com/ConCash/Termos/Politica_Privacidade.pdf" rel="noreferrer"
                            >
                            políticas de privacidade
                            </a>
                        </div>
                        <V3Button
                            onClick={handleSendCNP}
                            estilo="cnp"
                            width={300}
                            text="Enviar"
                        />
                        <div className={StyleM.fontInter12}  style={{ fontStyle: "italic"}}>
                            <span style={{ textAlign: "left"}}>* Preenchimento Obrigatório</span>
                        </div>
                    </>
                </div>
              
              <VantagensMobile
                title={"Vantagens de vender a sua cota da CNP Seguradora com a Concash"}
                estilo={estilo}
                adm={landing}
                title1={ "Proposta em até 24h" }
                text1={ "Envie os seus dados e receba uma proposta em até 24 horas." }
                alt1={ "ícone de proposta rápida" }
                title2={ "Pagamento à vista" }
                text2={ "Receba o dinheiro direto na sua conta, em poucos dias úteis." }
                alt2={ "ícone de pagamento à vista" }
                title3={ "Atendimento" }
                text3={ "Nós conduzimos o processo de venda desde a aprovação ao pagamento final da cota." }
                alt3={ "ícone de atendimento" }
              />
            </S.MobilePageContent>
          </>

          <FooterMobile landing={landing} estilo={estilo} />
        </>
      )}

      {/* Desktop */}
      {!mobile && (
        <>
          {loading && (
            <Backdrop open={loading} style={{ zIndex: 999 }}>
              <Lottie animationData={iconLoading} width={"40%"} />
            </Backdrop>
          )}

          <>
            {cookies && (
              <Backdrop style={{ zIndex: 999 }} open={cookies}>
                <S.CookieBoxDesktop>
                  <S.TextFooterDesktop>
                    Nós armazenamos dados temporariamente para melhorar a sua experiência de navegação e recomendar 
                    conteúdo do seu interesse. Ao continuar navegando, você concorda com a nossa política de privacidade.
                  </S.TextFooterDesktop>

                  <S.ButtonCookie style={{ backgroundColor: estilo?.backgroundColor }} onClick={() => setCookies(false)} >
                    CONTINUAR
                  </S.ButtonCookie>
                </S.CookieBoxDesktop>
              </Backdrop>
            )}
            <S.DesktopPageContainer>
              <S.DesktopHeader style={{ backgroundColor: estilo?.backgroundColor }} >
                <S.DesktopBoxLogo>
                  <img
                    loading="lazy"
                    src={estilo?.adminLogo}
                    width={"120px"}
                    height={"48px"}
                    alt="adm"
                  />
                  <img
                    loading="lazy"
                    src={ConcashMobile}
                    height={"40px"}
                    alt="concash"
                    style={{ marginLeft: "50px"}}
                  />
                </S.DesktopBoxLogo>
                <S.DesktopBoxTitle>
                    <S.DesktopHeaderTitle>
                      Venda seu consórcio CNP Seguradora com a melhor oferta do mercado
                    </S.DesktopHeaderTitle>
                </S.DesktopBoxTitle>

                <S.DesktopBoxButton>
                    <S.DesktopBoxButtonCNP>
                        <S.DesktopHeaderButton onClick={ () => (window.location.href = "#form") } style={{ backgroundColor: "#D70064" }} >
                            <p style={{ color: "#FFF" }}><b>QUERO VENDER</b></p>
                        </S.DesktopHeaderButton>

                        <S.DesktopHeaderButton onClick={ () => window.open("https://cliente.hml.concash.com.br/autoservico/cnp", "_blank") }  style={{ backgroundColor: "#D70064" }}>
                            <p style={{ color: "#FFF" }}><b>ACOMPANHAR PROPOSTA</b></p>
                        </S.DesktopHeaderButton>
                    </S.DesktopBoxButtonCNP>
                </S.DesktopBoxButton>
                
                <S.DesktopPhotoBox style={coverDesktopStyle}>
                    <img
                        src={ estilo?.backgroundCover }
                        alt={ estilo?.altBackground }
                        style={{
                            display: "flex",
                            borderRadius: "190px 190px 0px 0px",
                            backgroundSize: "cover",
                            position: "absolute",
                            width: "400px",
                            height: "344px",
                            objectFit: "cover",
                        }}
                    />
                </S.DesktopPhotoBox>

                <img
                  loading="lazy"
                  src={Tip1}
                  style={{ position: "absolute", top: 0, right: 130, width: 116, zIndex: 0, }}
                  alt="tip1"
                />

                <img
                  loading="lazy"
                  src={Tip2}
                  style={{ position: "absolute", top: 263, right: 623, width: 116, zIndex: 0, }}
                  alt="tip2"
                />
              </S.DesktopHeader>
            </S.DesktopPageContainer>
            <S.DesktopPageContent>
                <S.DesktopComoFunciona>
                    <S.DesktopCFBoxTitle>
                        <S.DesktopCFTitle style={{ color: estilo?.backgroundColor }} >
                            Veja como funciona: é 100% digital, rápido e seguro
                        </S.DesktopCFTitle>
                    </S.DesktopCFBoxTitle>
                    <S.DesktopCFBoxCards>
                        <ComoFuncionaCardDesktop
                            estilo={estilo}
                            icon={iconP[landing][1]}
                            title="1. Preencha o formulário"
                            content={ "Você envia as informações do seu consórcio através de um formulário aqui no site." }
                            adm={landing}
                            alt="ícone de preenchimento de formulário"
                        />
                        <ComoFuncionaCardDesktop
                            estilo={estilo}
                            icon={iconP[landing][2]}
                            title="2. Envie a documentação"
                            content="Envie a documentação e assine o contrato. Todo processo é 100% digital e com todo suporte da nossa equipe de especialistas."
                            adm={landing}
                            alt="ícone de envio de documentação"
                        />
                        <ComoFuncionaCardDesktop
                            estilo={estilo}
                            icon={iconP[landing][3]}
                            title="3. Dinheiro nas suas mãos"
                            content="Com a assinatura do contrato, basta aguardar a transferência de titularidade e o dinheiro é depositado direto na sua conta."
                            adm={landing}
                            alt="ícone de dinheiro nas suas mãos"
                        />
                    </S.DesktopCFBoxCards>
                </S.DesktopComoFunciona>
            </S.DesktopPageContent>

            <div
                id="form"
                style={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "column",
                    gap: 15,
                    margin: "20px 200px 50px 200px",
                    padding: "0 100px",
                    alignContent: "center",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <h2 className="title-vantagens" style={{ marginBottom: 20, color: landing === "cnp" ? "#002364" : "#000"  }}>
                    {formProps?.formName} 
                </h2>
                <div>
                    <V3TextCustom
                        id={formProps?.fields[0]?.name}
                        sx={{ width: 500 }}
                        size="small"
                        onChange={handleNomeRazao}
                        label={formProps?.fields[0]?.name}
                        error={nomeRazaoerr}
                        helperText={ nomeRazaoerr && `Preencha ${formProps?.fields[0]?.name}` }
                        required
                        value={nomeRazao}
                    />
                </div>
                <V3TextCustom
                    id={formProps?.fields[3]?.name}
                    sx={{ width: 500 }}
                    size="small"
                    onChange={(e: any) => handleCpfCnpj(e.target.value)}
                    label={formProps?.fields[3]?.name}
                    error={cpfCnpjerr}
                    helperText={ cpfCnpjerr && `Preencha corretamente ${formProps?.fields[3]?.name}` }
                    required
                    value={cpfCnpj}
                />
                <div style={{ display: "flex", gap: 10 }}>
                    <V3TextCustom
                        id={formProps?.fields[1]?.name}
                        sx={{ minWidth: 250 }}
                        size="small"
                        onChange={(e: any) => handleTelefone(e.target.value)}
                        label={formProps?.fields[1]?.name}
                        error={telefoneerr}
                        helperText={ telefoneerr && `Preencha ${formProps?.fields[1]?.name}` }
                        required
                        value={telefone}
                    />
                    <FormControlLabel
                        value="sim"
                        style={{ display: "flex", alignSelf: "left", minWidth: 220 }}
                        control={
                            <Checkbox
                                checked={contatoWhatsapp}
                                style={{ display: "flex", flex: 1, justifyContent: "flex-start" }}
                                onChange={ () => setContatoWhatsapp(!contatoWhatsapp) }
                                value={0}
                                name="radio-buttons"
                                inputProps={{ "aria-label": "A" }}
                                sx={{
                                    color: estilo.buttonColor,
                                    "&.Mui-checked": { color: estilo.buttonColor },
                                }}
                            />
                        }
                        label={formProps?.fields[2]?.name}
                    />
                </div>

                <V3TextCustom
                    id={formProps?.fields[4]?.name}
                    sx={{ width: 500 }}
                    size="small"
                    onChange={(e: any) => handleEmail(e.target.value)}
                    label={formProps?.fields[4]?.name}
                    error={emailerr}
                    helperText={ emailerr && `Preencha ${formProps?.fields[4]?.name}` }
                    required
                    value={email}
                />

                <div
                    style={{
                        display: "flex",
                        flex: 1,
                        alignItems: "center",
                        justifyContent: "center",
                        gap: 10,
                        margin: "0 210px",
                        paddingLeft: 22,
                    }}
                >
                    <V3TextCustom
                        label="Grupo"
                        style={{ minWidth: 245 }}
                        onChange={(e) => {
                          const text = e.target.value;
                          setGrupo(text);
                      }}
                        size="small"
                        value={grupo}
                        error={grupoerr}
                    />
                    <V3TextCustom
                        label="Cota"
                        style={{ minWidth: 245 }}
                        onChange={(e) => {
                            const text = e.target.value.replace(/[^\d]/g, "");
                            if (text.length <= 5) { setCota(text); }
                        }}
                        size="small"
                        value={cota}
                        error={cotaerr}
                    />

                    <div style={{ position: "relative", right: "-10px" }}>
                        <ImInfo onClick={() => setHasInfoCNP(true)} style={{ cursor: "pointer" }} />
                    </div>
                </div>
                <div className={StyleM.fontInter12}>
                    Ao enviar este formulário você concorda com as nossas{" "}
                    <a
                        target="_blank"
                        href="https://concash-prod-files-pub.s3.sa-east-1.amazonaws.com/ConCash/Termos/Politica_Privacidade.pdf" rel="noreferrer"
                    >
                        políticas de privacidade
                    </a>
                </div>
                <V3Button onClick={handleSendCNP} estilo="cnp" width={500} text="Enviar" />
                <div className={StyleM.fontInter12} style={{ fontStyle: "italic"}}>
                    * Preenchimento Obrigatório
                </div>
            </div>

            <VantagensDesktop
              title={ "Vantagens de vender a sua cota da CNP Seguradora com a Concash" }
              estilo={estilo}
              adm={landing}
              title1={ "Proposta em até 24h" }
              text1={ "Envie os seus dados e receba uma proposta em até 24 horas." }
              alt1={ "ícone de proposta rápida" }
              title2={ "Pagamento à vista" }
              text2={ "Receba o dinheiro direto na sua conta, em poucos dias úteis." }
              alt2={ "ícone de pagamento à vista" }
              title3={ "Atendimento" }
              text3={ "Nós conduzimos o processo de venda desde a aprovação ao pagamento final da cota." }
              alt3={ "ícone de atendimento" }
            />
          </>

          <FooterDesktop landing={"cnp"} estilo={estilo} />
        </>
      )}
    </>
  ) : (
    <></>
  );
};

export default Home;