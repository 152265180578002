import React from "react";
import { CardVantagensDesktop, CardVantagensMobile } from "./card";

import V1C from "../../assets/v1-gm.svg";
import V2C from "../../assets/v2-gm.svg";
import V3C from "../../assets/v3-gm.svg";
import V1S from "../../assets/v1-san.svg";
import V2S from "../../assets/v2-san.svg";
import V3S from "../../assets/v3-san.svg";
import V1M from "../../assets/v1-mg.svg";
import V2M from "../../assets/v2-mg.svg";
import V3M from "../../assets/v3-mg.svg";
import V1CNP from "../../assets/v1-cnp.png";
import V2CNP from "../../assets/v2-cnp.png";
import V3CNP from "../../assets/v3-cnp.png";
import V1CA from "../../assets/v1-canopus.svg";
import V2CA from "../../assets/v2-canopus.svg";
import V3CA from "../../assets/v3-canopus.svg";
import V1V from "../../assets/v1-volks.svg";
import V2V from "../../assets/v2-volks.svg";
import V3V from "../../assets/v3-volks.svg";
import V1P from "../../assets/v1-porto.svg";
import V2P from "../../assets/v2-porto.svg";
import V3P from "../../assets/v3-porto.svg";
import V1R from "../../assets/v1-randon.svg";
import V2R from "../../assets/v2-randon.svg";
import V3R from "../../assets/v3-randon.svg";
import "./vantagens.css";
import { ButtonVender, ButtonVenderMobile } from "../../Home/styles";

interface VantagensProps {
  title: string;
  estilo: any;
  adm: any;
  title1: string,
  text1: string,
  alt1: string,
  title2: string,
  text2: string,
  alt2: string,
  title3: string,
  text3: string,
  alt3: string,
}

export const VantagensDesktop: React.FC<any> = ({
  title,
  estilo,
  adm,
  title1,
  text1,
  alt1,
  title2,
  text2,
  alt2,
  title3,
  text3,
  alt3,
}: VantagensProps) => {
  const iconV: any = {
    santander: ["", V1S, V2S, V3S],
    chevrolet: ["", V1C, V2C, V3C],
    magalu: ["", V1M, V2M, V3M],
    porto: ["", V1P, V2P, V3P],
    cnp: ["", V1CNP, V2CNP, V3CNP],
    bb: ["", V1CNP, V2CNP, V3CNP],
    banrisul: ["", V1M, V2M, V3M],
    canopus: ["", V1CA, V2CA, V3CA],
    volkswagen: ["", V1V, V2V, V3V],
    randon: ["", V1R, V2R, V3R],
  };

  return (
    <div className="container-vantagens">
      <div className="box-title-vantagens">
        <h2
          className="title-vantagens"
          style={{ color: estilo.backgroundColor }}
        >
          {title}
        </h2>
      </div>
      <div className="box-content-vantagens">
        <CardVantagensDesktop
          icon={iconV[adm][1]}
          title={ title1 }
          content={ text1 }
          alt={ alt1 }
        />
        <CardVantagensDesktop
          icon={iconV[adm][2]}
          title={ title2 }
          content={ text2 }
          alt={ alt2 }
        />
        <CardVantagensDesktop
          icon={iconV[adm][3]}
          title={ title3 }
          content={ text3 }
          alt={ alt3 }
        />
      </div>

      <div className="box-button" style={{ alignContent: "center", justifyContent: "center" }} >
        <ButtonVender
          href={ "#form" }
          style={{
            background: adm == "canopus" ? estilo?.buttonColor : estilo?.backgroundColor ,
            color: "#FFFFFF",
            marginTop: 40,
            maxWidth: 450,
            cursor: "pointer",
            marginBottom: "30px",
          }}
        >
          Quero Vender
        </ButtonVender>
      </div>
    </div>
  );
};
export const VantagensMobile: React.FC<any> = ({
  title,
  estilo,
  adm,
  title1,
  text1,
  alt1,
  title2,
  text2,
  alt2,
  title3,
  text3,
  alt3,
}: VantagensProps) => {
    const iconV: any = {
      santander: ["", V1S, V2S, V3S],
      chevrolet: ["", V1C, V2C, V3C],
      magalu: ["", V1M, V2M, V3M],
      porto: ["", V1M, V2M, V3M],
      cnp: ["", V1CNP, V2CNP, V3CNP],
      banrisul: ["", V1M, V2M, V3M],
      canopus: ["", V1M, V2M, V3M],
      volkswagen: ["", V1M, V2M, V3M],
      randon: ["", V1M, V2M, V3M],
    };
  return (
    <div className="container-vantagens-mobile">
      <div className="box-title-vantagens-mobile">
        <h2
          className="title-vantagens-mobile"
          style={{ color: estilo.backgroundColor }}
        >
          {title}
        </h2>
      </div>
      <div className="box-content-vantagens-mobile">

        <CardVantagensMobile
          icon={iconV[adm][1]}
          title={ title1 }
          content={ text1 }
          alt={ alt1 }
        />

        <CardVantagensMobile
          icon={iconV[adm][2]}
          title={ title2 }
          content={ text2 }
          alt={ alt2 }
        />

        <CardVantagensMobile
          icon={iconV[adm][3]}
          title={ title3 }
          content={ text3 }
          alt={ alt3 }
        />

      </div>
      <ButtonVenderMobile
        href={ "#form" }
        style={{
          flex: 1,
          background: adm === "cnp" ? estilo?.buttonColor : estilo?.backgroundColor,
          color: "#FFFFFF",
          minHeight: 45,
          width: "100%",
          textDecoration: "none",
          cursor: "pointer",
          margin: "20px 0",
        }}
      >
        Quero Vender
      </ButtonVenderMobile>
    </div>
  );
};
