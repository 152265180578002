import React, { useState } from "react";
import styled from "styled-components";
import { v3Colors } from "../../v3/colors";
import { Autocomplete, TextField } from "@mui/material";
import { withStyles } from "@mui/styles";

import "./input.css";

export const V3TextInput = (props) => {
  const { label, width } = props;

  return (
    <>
      <V3StyledText
        label={label}
        sx={{ width: width }}
        activeBorderColor="green"
      ></V3StyledText>
    </>
  );
};

export const V3StyledTextCustomized = withStyles({
  root: {
    borderColor: "#ABABAB !important",

    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: `10px`,
        borderColor: "#ABABAB",
        borderWidth: `1px`,
        color: "#979797 !important",
        fontWeight: "400",
        margin: 0,
        maxHeight: 53,
        padding: 0,
      },
      "&.Mui-focused": {
        backgroundColor: v3Colors.inputDisabled,
        borderColor: "#ABABAB !important",
        borderRadius: `10px`,
        borderWidth: `1px`,
        maxHeight: 53,

        color: v3Colors.black,
        fontWeight: "400",
      },
    },
  },
})(TextField);

const V3StyledText = withStyles({
  root: {
    borderColor: "#ABABAB !important",

    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: `10px`,
        borderColor: "#ABABAB",
        borderWidth: `1px`,
        color: "#979797 !important",
        fontWeight: "400",
        margin: 0,
        padding: 0,
      },
      "&.Mui-focused": {
        backgroundColor: v3Colors.inputDisabled,
        borderColor: "#ABABAB !important",
        borderRadius: `10px`,
        borderWidth: `1px`,

        color: v3Colors.black,
        fontWeight: "400",
      },
    },
  },
})(TextField);

const V3Autocomplete = withStyles({
  root: {
    "& .MuiInputBase-root": {
      minHeight: 50,
      display: "flex",
      justifyContent: "center",
      textAlign: "center",
      font: "normal normal normal 15px/20px Roboto",
      letterSpacing: 0,
      color: v3Colors.black,
      backgroundColor: v3Colors.white,
      padding: "20px",
      opacity: 1,
    },

    "& .MuiFormControl-fullWidth": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      alignContent: "center",
      backgroundColor: v3Colors.white,
    },

    "& .MuiOutlinedInput-root": {
      padding: "0 10px",
      "& fieldset": {
        borderRadius: `10px`,
        borderColor: "#ABABAB",
        borderWidth: `1px`,
        color: v3Colors.black,
        backgroundColor: "transparent",
        fontWeight: "bold",
      },
      "&.Mui-focused": {
        backgroundColor: v3Colors.inputDisabled,
        borderRadius: `10px`,
        color: v3Colors.black,
        backgroundColor: v3Colors.white,
        fontWeight: "bold",
      },
    },
  },
})(Autocomplete);

export const V3Select = (props) => {
  const {
    options,
    id,
    name,
    action,
    label,
    width,
    disabled,
    error,
    height,
    helperText,
    bgcolor,
    size
  } = props;
  const [value, setValue] = useState("");

  return (
    <>
      <V3Autocomplete
        id={id}
        sx={{
          borders: 0,
          display: "flex",
          borderColor: v3Colors.white,
          width: width,
          height: height ? height : 55,
          alignItems: "center",
          justifyContent: "center",
        }}
        color={v3Colors.black}
        options={options}
        onChange={(event, newValue) => {
          if (!newValue) {
            setValue(null);
            action(null);
          } else {
            setValue(newValue);
            action(newValue);
          }
        }}
        size={"small"}
        value={value}
        disabled={disabled}
        renderInput={(params) => (
          <V3TextCustom
            label={label}
            error={error}
            helperText={helperText}
            {...params}
            size="medium"
            sx={{
              border: 0,
              padding: 0,
              backgroundColor: v3Colors.white,
            }}
          />
        )}
      />
    </>
  );
};

export const V3CustomTextField = (props) => {
  const { width, label, required, onChange, value } = props;

  const V3CustomTextFieldStyled = withStyles({
    root: {
      "& .Mui-focused": {
        borderRadius: `10px`,
        borderColor: "#ABABAB",
        color: "#979797 !important",
      },
      "& .MuiInputAdornment-sizeSmall": {
        borderRadius: `10px`,
        borderColor: "#ABABAB",
        color: "#979797 !important",
      },
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderRadius: `10px`,
          borderColor: "#ABABAB",
          color: "#979797 !important",
          width: width,
        },
      },
    },
  })(TextField);

  return (
    <V3CustomTextFieldStyled
      style={{}}
      size="small"
      onChange={onChange}
      label={label}
      required={required}
      value={value}
    />
  );
};

export const V3TextCustomContact = withStyles({
  root: {
    "& .MuiInputBase-root": {
      height: "50px",
      alignItems: "center",
    },
    "& .Mui-focused": {
      borderRadius: `10px`,
      borderColor: "#ABABAB",
      color: "#979797 !important",
    },
    "& .MuiInputAdornment-sizeSmall": {
      borderRadius: `10px`,
      borderColor: "#ABABAB",
      color: "#979797 !important",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: `10px`,
        borderColor: "#ABABAB",
        color: "#979797 !important",
      },
    },
  },
})(TextField);

export const V3TextCustom = withStyles({
  root: {
    "& .MuiInputBase-input": {
      paddingLeft: 15,
      backgroundColor: v3Colors.white,
    },

    "&.MuiAutocomplete-input": {
      padding: "7.5px 4px 7.5px 15px",
      backgroundColor: "transparent",
    },
    "& .Mui-focused": {
      borderRadius: `10px`,
      borderColor: "#ABABAB",
    },
    "& .MuiInputAdornment-sizeSmall": {
      borderRadius: `10px`,
      borderColor: "#ABABAB",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: `10px`,
      },
    },
  },
})(TextField);

export const V3FileInput = (props) => {
  const { label, width, action, backgroundColor, color } = props;
  const [file, setFile] = useState("");

  function handleFile(e) {
    const arquivo = e.target.files[0];
    if (e.target.files[0]) {
      setFile(arquivo);
      action(arquivo);
    } else {
      setFile(null);
      action(null);
    }
  }

  return (
    <>
      <label class="input-personalizado">
        <div
          style={{
            display: "flex",
            height: 45,
            width: width,
            justifyContent: "space-between",
            border: "1px solid #979797",
            alignItems: "center",
            paddingRight: 10,
            paddingLeft: 10,
            borderRadius: 10,
            backgroundColor: v3Colors.white,
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <span className="text-box2">{file?.name}</span>
          </div>
          <div
            style={{
              display: "flex",
              flex: 1,
              position: "relative",
              top: -23,
              left: 0,
              padding: 3,
              backgroundColor: "transparent",
            }}
          >
            <span className="text-box2" 
                  style={{ 
                    backgroundColor: backgroundColor ? backgroundColor : v3Colors.white,
                    color: color ? color : v3Colors.black,
                  }}>{label}</span>
          </div>

          <div className="gradient-box">
            <span className="text-box">Escolher Arquivo</span>
          </div>
          <input
            type="file"
            onChange={(e) => handleFile(e)}
            class="input-file"
            accept=".pdf"
            style={{ backgroundColor: backgroundColor ? backgroundColor : v3Colors.white }}
          />
        </div>
      </label>
    </>
  );
};

export const V3FileInputMobile = (props) => {
  const { label, width, action } = props;
  const [file, setFile] = useState("");

  function handleFile(e) {
    const arquivo = e.target.files[0];
    if (e.target.files[0]) {
      setFile(arquivo);
      action(arquivo);
    } else {
      setFile(null);
      action(null);
    }
  }

  return (
    <>
      <label class="input-personalizado">
        <div
          style={{
            display: "flex",

            height: 45,
            width: width,
            justifyContent: "space-between",
            border: "1px solid #979797",
            alignItems: "center",
            paddingRight: 10,
            paddingLeft: 10,
            borderRadius: 10,
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <span className="text-box2">{file?.name}</span>
          </div>
          <div
            style={{
              display: "flex",
              flex: 1,
              position: "relative",
              top: -23,
              left: 0,
              padding: 3,
              backgroundColor: "transparent",
            }}
          >
            <span className="text-box2">{!file?.name && label}</span>
          </div>

          <div className="gradient-box">
            <span className="text-box">Escolher Arquivo</span>
          </div>
          <input
            type="file"
            onChange={(e) => handleFile(e)}
            class="input-file"
            accept=".pdf"
          />
        </div>
      </label>
    </>
  );
};
