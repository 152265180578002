import { useState, useEffect } from "react";
import {
  BoxPinStep,
  BoxStep,
  BoxStepDesktop,
  BoxStepMobile,
  CenteredBox,
  CenteredBoxMobile,
} from "../../../styles/themes/components/box";
import {
  V3Button,
  V3RadioButton,
} from "../../../styles/themes/components/button";
import { PinStepActive } from "../../../styles/themes/components/pin";
import {
  V3PropostaText,
  V3Text5,
  V3TextFooter,
  V3TextH1,
  V3TextInputInfo,
} from "../../../styles/themes/components/texts";
import { v3Colors } from "../../../styles/themes/v3/colors";
import FloatButton from "../../../components/FloatButton";

import { ImInfo } from "react-icons/im";
import { V3Modal } from "../../../styles/themes/components/modal";
import { formataCPF } from "../../../utils/formaters";
import { v3ValidateEmail } from "../../../styles/themes/utils/validate";
import { Checkbox, FormControlLabel } from "@mui/material";
import {
  ContainerDesktopOPT,
  ContainerMobileOPT,
} from "../../../styles/themes/components/shared/styles";
import ConcashLogo from "../../../assets/images/logo-negative.png"
import { BsWhatsapp } from "react-icons/bs";
import { RiPhoneLine } from "react-icons/ri";
import moment from "moment";
export const NaoAtendeVender = (props) => {
  const { navigtion } = props;
  const mobile = window.matchMedia("(max-width:768px)").matches;
  const whatsapp = "https://api.whatsapp.com/send/?phone=551140405686&text=Ol%C3%A1%2C+quero+vender+minha+cota+de+consorcio+para+a+Concash%21&type=phone_number&app_absent=0";

  useEffect(() => {
    localStorage.removeItem("vc");
    localStorage.removeItem("vcObj");
    localStorage.removeItem("etapasO");
    localStorage.removeItem("idEtapaO");
    localStorage.removeItem("idEtapaAtualO");
    localStorage.removeItem("etapaAtualO");
    localStorage.removeItem("administradoraO");
    localStorage.removeItem("channel");
    localStorage.removeItem("horaAcesso");
    localStorage.removeItem("startOnboard");
    localStorage.removeItem("startForm");
    localStorage.removeItem("tipoConsorcioO");
  }, []);

  return (
    <>
      {mobile && (
        <>
          <ContainerMobileOPT>
            <div
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "column",
                alignContent: "center",
                alignItems: "center",
                justifyContent: "center",
                paddingTop: 40,
              }}
            >
              <img
                loading="lazy"
                onClick={() => (window.location.href = "/")}
                src={ConcashLogo}
                style={{ marginBottom: 10 }}
                width={110}
                height={30}
                alt="logo"
              />
              <V3Text5
                style={{
                  alignSelf: "center",
                  textAlign: "center",
                  marginBottom: 10,
                  padding: "10px 30px ",
                  fontWeight: "400",
                  lineHeight: 1.3,
                  fontSize: 15,
                }}
              >
                Venda seu consórcio online com segurança, rapidez e
                transparência. <br />
                Caso queira saber mais sobre a Concash{" "}
                <a href="/" style={{ color: v3Colors.grayHard }}>
                  clique aqui
                </a>
                .
              </V3Text5>
              <BoxStepMobile style={{ minHeight: 450 }}>
                <BoxPinStep>
                  <PinStepActive num={1} type="checked" />
                  <PinStepActive num={2} type="checked" />
                  <PinStepActive num={3} type="checked" />
                </BoxPinStep>
                <CenteredBoxMobile>
                  <V3TextH1 style={{ textAlign: "center" }}>
                    A Concash agradece o seu contato!
                  </V3TextH1>
                </CenteredBoxMobile>
                <CenteredBoxMobile style={{ marginTop: "15px" }}>
                  <V3Text5 style={{ textAlign: "center" }}>
                    Infelizmente ainda não temos parceria com sua administradora
                    de consórcio. Mas não desanime! Caso a gente estabeleça uma
                    parceria com sua administradora entraremos em contato com
                    você!
                  </V3Text5>
                </CenteredBoxMobile>

                <CenteredBoxMobile style={{ gap: 20, marginTop: 30 }}>
                  <div
                    onClick={() => (window.location.href = "tel:+551140405686")}
                    style={{
                      display: "flex",
                      width: 250,
                      height: 50,
                      borderRadius: 8,
                      gap: 15,
                      backgroundColor: v3Colors.primary,
                      color: v3Colors.white,
                      alignItems: "center",
                      justifyContent: "center",
                      fontFamily: "Roboto",
                      fontWeight: "500",
                    }}
                  >
                    <RiPhoneLine size={24} />
                    <span>(11) 4040-5686</span>
                  </div>
                </CenteredBoxMobile>
                <CenteredBoxMobile style={{ gap: 20, marginTop: 30 }}>
                  <div
                    onClick={() => window.open(whatsapp, "_blank")}
                    style={{
                      display: "flex",
                      width: 250,
                      height: 50,
                      borderRadius: 8,
                      gap: 15,
                      backgroundColor: v3Colors.primary,
                      color: v3Colors.white,
                      alignItems: "center",
                      justifyContent: "center",
                      fontFamily: "Roboto",
                      fontWeight: "500",
                    }}
                  >
                    <BsWhatsapp size={24} />
                    <span>(11) 4040-5696</span>
                  </div>
                </CenteredBoxMobile>
              </BoxStepMobile>
            </div>
            <div>
              <V3TextFooter>
                © Copyright {moment(new Date()).format("YYYY")} Concash. Todos
                os direitos reservados{" "}
              </V3TextFooter>
            </div>
            <FloatButton
              direction="right"
              color="#25d366"
              radius="100px"
              opcional
              link="https://api.whatsapp.com/send/?phone=551140405686&&type=phone_number&app_absent=0"
              icon={<BsWhatsapp color="#FFF" size={30} />}
            />
          </ContainerMobileOPT>
        </>
      )}
      {!mobile && (
        <ContainerDesktopOPT>
          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              alignContent: "center",
              alignItems: "center",
              justifyContent: "center",
              paddingTop: 40,
            }}
          >
            <img
              loading="lazy"
              onClick={() => (window.location.href = "/")}
              src={ConcashLogo}
              style={{ marginBottom: 10 }}
              width={110}
              height={30}
              alt="logo"
            />
            <V3Text5
              style={{
                alignSelf: "center",
                textAlign: "center",
                marginBottom: 10,
                padding: "10px 30px ",
                fontWeight: "400",
                lineHeight: 1.3,
                fontSize: 15,
              }}
            >
              Venda seu consórcio online com segurança, rapidez e transparência.
              <br /> Caso queira saber mais sobre a Concash{" "}
              <a href="/" style={{ color: v3Colors.grayHard }}>
                clique aqui
              </a>
              .
            </V3Text5>
            <BoxStepDesktop style={{ minHeight: 450 }}>
              <BoxPinStep>
                <PinStepActive num={1} type="checked" />
                <PinStepActive num={2} type="checked" />
                <PinStepActive num={3} type="checked" />
              </BoxPinStep>
              <CenteredBoxMobile>
                <V3TextH1 style={{ textAlign: "center" }}>
                  A Concash agradece o seu contato!
                </V3TextH1>
              </CenteredBoxMobile>
              <CenteredBoxMobile style={{ marginTop: "15px" }}>
                <V3Text5 style={{ textAlign: "center" }}>
                  Infelizmente ainda não temos parceria com sua administradora
                  de consórcio. Mas não desanime! Caso a gente estabeleça uma
                  parceria com sua administradora entraremos em contato com
                  você!
                </V3Text5>
              </CenteredBoxMobile>

              <CenteredBox
                style={{
                  marginTop: 15,
                  padding: "0px 100px",
                }}
              ></CenteredBox>
              <CenteredBox style={{ gap: 20, marginTop: 30 }}>
                <div
                  onClick={() => window.open(whatsapp, "_blank")}
                  style={{
                    display: "flex",
                    width: 250,
                    height: 50,
                    borderRadius: 8,
                    gap: 15,
                    backgroundColor: v3Colors.primary,
                    color: v3Colors.white,
                    alignItems: "center",
                    justifyContent: "center",
                    fontFamily: "Roboto",
                    fontWeight: "500",
                    cursor: "pointer",
                  }}
                >
                  <BsWhatsapp size={24} />
                  <span>(11) 4040-5696</span>
                </div>
              </CenteredBox>

            </BoxStepDesktop>
          </div>

          <div style={{ position: "relative", bottom: -15 }}>
            <V3TextFooter style={{ textAlign: "center" }}>
              © Copyright {moment(new Date()).format("YYYY")} Concash.
              <br />
              Todos os direitos reservados{" "}
            </V3TextFooter>
            <FloatButton
              direction="right"
              color="#25d366"
              radius="100px"
              opcional
              link="https://api.whatsapp.com/send/?phone=551140405686&&type=phone_number&app_absent=0"
              icon={<BsWhatsapp color="#FFF" size={30} />}
            />
          </div>
        </ContainerDesktopOPT>
      )}
    </>
  );
};
