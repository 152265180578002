import React, { useEffect, useState } from "react";
import * as Type from "./types";
import * as S from "./styles";
import Plus from "../assets/plus-icon.png";
import ConcashMobile from "../assets/concashlogo.svg";
import Tip1 from "../assets/tip1.svg";
import Tip2 from "../assets/tip2.svg";
import P1S from "../assets/p1-san.svg";
import P2S from "../assets/p2-san.svg";
import P3S from "../assets/p3-san.svg";
import P1C from "../assets/p1-gm.svg";
import P2C from "../assets/p2-gm.svg";
import P3C from "../assets/p3-gm.svg";
import P1M from "../assets/p1-mg.svg";
import P2M from "../assets/p2-mg.svg";
import P3M from "../assets/p3-mg.svg";
import "./reset.css";
import axios from "axios";
import { DateTime } from "luxon";
import Lottie from "lottie-react";
import CNPLoading from "../../../../assets/lottie/cnp-load.json";
import SanLoading from "../../../../assets/lottie/santander-loading.json";
import moment from "moment";
import checkDevice from "../../../../utils/isMobile";
import {
  ComoFuncionaCardDesktop,
  ComoFuncionaCardMobile,
} from "../components/comofunciona/ComoFuncionaCard";
import api from "../../../../services/api";
import {
  CotaFormDesktop,
  CotaFormMobile,
} from "../components/CotaForm/CotaForm";
import {
  VantagensDesktop,
  VantagensMobile,
} from "../components/Vantagens/Vantagens";
import { FooterDesktop, FooterMobile } from "../components/Footer/Footer";
import { Backdrop, Checkbox, FormControlLabel, TextField } from "@mui/material";
import { SucessoDesktop, SucessoMobile } from "../components/Sucesso/Sucesso";
import SImage from "../assets/img-santander.jpg";
import { BsWhatsapp } from "react-icons/bs";
import { Helmet } from "react-helmet-async";
import { V3FileInput, V3TextCustom } from "../../../../styles/themes/components/input";
import { validNome } from "../../../../utils/validate";
import {
  v3CellphoneFormater,
  v3FormataCNPJ,
  v3MaskCPF,
} from "../../../../styles/themes/utils/format";
import { v3Colors } from "../../../../styles/themes/v3/colors";
import {
  v3ValidaCpfCnpj,
  v3ValidateEmail,
} from "../../../../styles/themes/utils/validate";
import { V3Modal } from "../../../../styles/themes/components/modal";
import { V3Text5 } from "../../../../styles/themes/components/texts";
import { ImInfo } from "react-icons/im";
import { Button } from "../../../../components/ScrollButton/styles";
import { V3Button } from "../../../../styles/themes/components/button";
import StyleM from "./LP.module.css";
import ContratoSantander from "../../../../assets/images/contrato-santander.png";
import TagManager from 'react-gtm-module'

const Home: React.FC = () => {
  const landing: any = window.location.pathname.replace("/", "");
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tituloLanding, setTituloLanding] = useState("");
  const [logoCessionario, setLogoCessionario] = useState(ConcashMobile);
  const mobile = window.matchMedia("(max-width:768px)").matches;
  const [estilo, setEstilo] = useState<Type.EstiloProps | null>(null);
  const [cookies, setCookies] = useState(true);
  const [formProps, setFormProps] = useState<any>();
  const [finished, setFinished] = useState(false);
  const [extrato, setExtrato] = useState("");
  const [iconLoading, setIconLoading] = useState<any>()

  const [nomeRazao, setNomeRazao] = useState("");
  const [nomeRazaoerr, setNomeRazaoerr] = useState(false);
  const [telefone, setTelefone] = useState("");
  const [telefoneerr, setTelefoneer] = useState(false);
  const [contatoWhatsapp, setContatoWhatsapp] = useState(false);
  const [cpfCnpj, setCpfCnpj] = useState("");
  const [cpfCnpjerr, setCpfCnpjerr] = useState(false);
  const [email, setEmail] = useState("");
  const [emailerr, setEmailerr] = useState(false);
  const [grupo, setGrupo] = useState("");
  const [grupoerr, setGrupoerr] = useState(false);
  const [cota, setCota] = useState("");
  const [cotaerr, setCotaerr] = useState(false);
  const [versao, setVersao] = useState("");
  const [versaoerr, setVersaoerr] = useState(false);
  const [contrato, setContrato] = useState("");
  const [contratoerr, setContratoerr] = useState(false);
  const [successLinkWP, setSuccessLinkWP] = useState("");

  const [hasInfoCNP, setHasInfoCNP] = useState(false);
  const [hasInfoSantander, setHasInfoSantander] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [hasSuccess, setHasSuccess] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [successText, setSuccessText] = useState("");

  const parametersTela: any = {
    santander: 62,
    chevrolet: 22,
    magalu: 34,
    porto: 46,
    banrisul: 114,
    canopus: 125,
    cnp: 123,
    volkswagen: 138,
  };

  const parametersForm: any = {
    santander: 45,
    chevrolet: 21,
    magalu: 21,
    porto: 46,
    cnp: 600,
    banrisul: 114,
    canopus: 125,
    volkswagen: 138,
  };

  const iconP: any = {
    santander: ["", P1S, P2S, P3S],
    chevrolet: ["", P1C, P2C, P3C],
    magalu: ["", P1M, P2M, P3M],
    porto: ["", P1M, P2M, P3M],
    cnp: ["", P1M, P2M, P3M],
    banrisul: ["", P1M, P2M, P3M],
    canopus: ["", P1M, P2M, P3M],
    volkswagen: ["", P1M, P2M, P3M],
  };

  const paramIdTela: any = parametersTela[landing];
  const paramIdForm: any = parametersForm[landing];
  const [horaAcesso, setHoraAcesso] = useState("");
  const [browserUser, setBrowserUser] = useState("");
  const [isMobileUser, setIsMobileUser] = useState(false);
  const [ipUser, setIpUser] = useState("");
  const [startForm, setStartForm] = useState("");
  const [hrAceite, setHrAceite] = useState("");
  const [canopusAceite, setCanopusAceite] = useState("");
  const [os, setOS] = useState("");
  const [timeZone, setTimeZone] = useState("");
  const coverMobileStyle = {
    width: "100%",
    borderRadius: "180px 180px 0px 0px",
    color: "#FFF",
  };

  const coverDesktopStyle = {
    display: "flex",
    borderRadius: "190px 190px 0px 0px",
    opacity: 0.6,
    zIndex: 0,
  };

  const actions: any = {
    nomeRazao: handleChange,
    telefone: setTelefone,
    contatoWhatsapp: setContatoWhatsapp,
    cpfCnpj: setCpfCnpj,
    email: setEmail,
    grupo: setGrupo,
    cota: setCota,
    versao: setVersao,
    contrato: setContrato,
  };

  const values: any = {
    nomeRazao: nomeRazao,
    telefone: setTelefone,
    contatoWhatsapp: setContatoWhatsapp,
    cpfCnpj: setCpfCnpj,
    email: setEmail,
    grupo: setGrupo,
    cota: setCota,
    versao: setVersao,
    contrato: setContrato,
  };

  function handleClear() {
    setNomeRazao("");
    setCpfCnpj("");
    setTelefone("");
    setContatoWhatsapp(false);
    setEmail("");
    setGrupo("");
    setCota("");
    setVersao("");
    setContrato("");
    setExtrato("");
  }

  function handleChange(c: any, v: any) {
    if (v === "nomeRazao") {
      setNomeRazao(c);
    }
  }

  function handleNomeRazao(text: any) {
    const valid: any = validNome(text.target.value);
    handleGetDataAccess();
    if (!text.target.value || !valid) {
      setNomeRazao(text.target.value);
      setNomeRazaoerr(true);
    } else {
      setNomeRazao(text.target.value);
      setNomeRazaoerr(false);
    }
  }

  const handleGetStyles = async () => {
    if (estilo) {
      return;
    } else {
      try {
        const { data } = await api.get(
          `api/dado-tela/1.0/buscar/${landing === "porto" ? "0" : paramIdTela}`
        );
        setEstilo(data);
        localStorage.setItem("administradoraO", `${parametersTela[landing]}`);
        localStorage.setItem("channel", "22");
      } catch (error) {
        console.log(error);
      }
    }
  };
  function horaAcessado() {
    if(horaAcesso) {
      return;
    }
    const acessado = moment([], true).format("DD/MM/YYYY  HH:mm:ss");
    setHoraAcesso(acessado);
  }

  function getNavigationData() {
    if (window.navigator.appVersion.indexOf("Win") !== -1) {
      setOS("Windows");
    }
    if (window.navigator.appVersion.indexOf("Mac") !== -1) {
      setOS("MacOS");
    }
    if (window.navigator.appVersion.indexOf("X11") !== -1) {
      setOS("UnixOS");
    }
    if (window.navigator.appVersion.indexOf("Linux") !== -1) {
      setOS("Linux");
    }

    if (window.navigator.userAgent.indexOf("Chrome") !== -1) {
      setBrowserUser("Google Chrome");
    } else if (window.navigator.userAgent.indexOf("Firefox") !== -1) {
      setBrowserUser("Mozilla Firefox");
    } else if (window.navigator.userAgent.indexOf("MSIE") !== -1) {
      setBrowserUser("Internet Explorer");
    } else if (window.navigator.userAgent.indexOf("Edge") !== -1) {
      setBrowserUser("Edge");
    } else if (window.navigator.userAgent.indexOf("Safari") !== -1) {
      setBrowserUser("Safari");
    } else if (window.navigator.userAgent.indexOf("Opera") !== -1) {
      setBrowserUser("Opera");
    }

    const isMobileDevice = checkDevice();
    setIsMobileUser(isMobileDevice);
  }

  async function handleGetIP() {
    if(ipUser) return;
    await axios
      .get("https://api.ipify.org?format=json")
      .then(({data}: any) => {
        setIpUser(data.ip);
      })
      .catch((error: any) => console.log(error));
  }
  async function handleGetForm() {
    try {
      const { data }: any = await api.get(
        `api/site/1.0/landing-form?idFormulario=${paramIdForm}`
      );
      setFormProps(data);
    } catch (error) {
      console.log(error);
    }
  }

  async function handleSetTitle() {
    try {
      if(landing === "cnp") {
        setIconLoading(CNPLoading);
      }
      if(landing === "santander") {
        setIconLoading(SanLoading);
      }
      const tituloPagina =
      landing === "santander"
      ? "Vender Consórcio Santander | Concash"
      : landing === "magalu"
        ? "Vender Consórcio Magalu | Concash" : ""
      setTituloLanding(tituloPagina);
    } catch (error) {
      console.log(error);
    }
  }

  function handleTelefone(t: any) {
    const validatePhone = t.replace(/[^0-9]/g, "").split("");
    if (t) {
      setTelefoneer(false);
      if (t.length > 15) {
        setTelefone(telefone);
      } else {
        setTelefone(v3CellphoneFormater(t));
      }
    }
    if (!t || t == "" || t == "(") {
      setTelefoneer(true);
      setTelefone(t);
    }

    if (t.length < 14 || validatePhone[0] === "0") {
      setTelefoneer(true);
    } else {
      setTelefoneer(false);
    }
  }

  function handleEmail(t: any) {
    const text = t.replaceAll(" ", "");
    if (v3ValidateEmail(text) || text === "") {
      setEmail(text);
      setEmailerr(false);
    } else {
      setEmail(text);
      setEmailerr(true);
    }
  }

  function handleCpfCnpj(text: string) {
    const validating = text.replace(/[^\d]/g, "");

    if (validating.length <= 14) {
      if (validating.length <= 11) {
        const cpfs = v3MaskCPF(validating);
        setCpfCnpj(cpfs);
        if (v3ValidaCpfCnpj(cpfs) || text === "") {
          setCpfCnpjerr(false);
        } else {
          setCpfCnpjerr(true);
        }
      } else {
        const cn = v3FormataCNPJ(validating);
        setCpfCnpj(cn);

        if (v3ValidaCpfCnpj(cn) || text === "") {
          setCpfCnpjerr(false);
        } else {
          setCpfCnpjerr(true);
        }
      }
    }
  }
  function handleAccept() {
    if (acceptTerms) {
      setAcceptTerms(acceptTerms);
    } else {
      const acessado = moment([], true).format("DD/MM/YYYY  HH:mm:ss");
      setHrAceite(acessado);
      setAcceptTerms(true);
      return acessado;
    }
  }

  function handleGetDataAccess() {
    if (startForm) {
      return;
    }
    const acessado = moment([], true).format("DD/MM/YYYY  HH:mm:ss");
    setStartForm(acessado);
    const tz = DateTime.local().zone.name;
    setTimeZone(tz);
  }

  async function handleSendCNP() {
    const aceito = handleAccept();
    const formData = new FormData();
    if (!nomeRazao || nomeRazaoerr) {
      setErrorText("Preencha corretamente Nome / Razão social.");
      return setHasError(true);
    }
    if (!cpfCnpj || cpfCnpjerr) {
      setErrorText("Preencha corretamente o CPF ou CNPJ.");
      return setHasError(true);
    }

    if (!telefone || telefoneerr) {
      setErrorText("Preencha corretamente o número de telefone.");
      return setHasError(true);
    }
    if (!email || emailerr) {
      setErrorText("Preencha corretamente o E-Mail.");
      return setHasError(true);
    }
    formData.append("grupo", grupo);
    formData.append("cota", cota);
    if(landing === "cnp") {

      if (!grupo || !cota) {
        setErrorText("Preencha corretamente grupo e cota.");
        return setHasError(true);
      }
    }
    if(landing === "santander") {
      formData.append("contrato", contrato);
      formData.append("extrato", extrato);
    }

    setLoading(true);
    const cpfcnpjParsed = parseInt(cpfCnpj.replace(/[^0-9]/g, ""));
    const tels: any = telefone?.split(" ");
    const ddd = tels[0]?.replace(/[^0-9]/g, "");
    const tel = tels[1]?.replace(/[^0-9]/g, "");
  

    formData.append("cpfCnpj", `${cpfcnpjParsed}`);
    formData.append("nomeRazao", nomeRazao);
    formData.append("email", email);
    formData.append("contatoWhatsapp", contatoWhatsapp ? "1" : "0");
    formData.append("dddTelefone", ddd);
    formData.append("telefone", tel);
    formData.append("idAdministradora", `${parametersTela[landing]}`);
    formData.append("idCnal", "22");
    formData.append("idTipoConsorcio", "41");
    formData.append("comoConheceuId", "0");
    const query = new URLSearchParams(window.location.search);
    
    var origem = query.get('utm_source')?.toString()
    formData.append("origem",(origem===undefined?'':origem))

    var midia = query.get('utm_medium')?.toString()
    formData.append("midia",(midia===undefined?'':midia))

    var campanha = query.get('utm_campaign')?.toString()
    formData.append("campanha",(campanha===undefined?'':campanha))

    var segmento = query.get('utm_term')?.toString()
    formData.append("segmento",(segmento===undefined?'':segmento))

    var anuncio = query.get('utm_content')?.toString()
    formData.append("anuncio",(anuncio===undefined?'':anuncio))
    
    const plataforma = `{
      "acessado_em": "${horaAcesso}", 
      "mobile": ${isMobileUser ? "SIM" : "NÃO"},
      "navegador": "${browserUser}", 
      "os": "${os}", 
      "ip": "${ipUser}", 
      "aceite_politica_em": "${hrAceite ? hrAceite : aceito}", 
      "inicio_form":" ${startForm}", 
      "timezone": "${timeZone}"    
  }`;
  formData.append("plataforma", plataforma);
    try {
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      await api
        .post("/api/site/1.0/melhor-proposta-website", formData)
        .then(({ data }: any) => {
          handleClear();
          if(landing === "cnp"){
            data.redirectUri && setSuccessLinkWP(data.redirectUri);
          }
          setSuccessText(
            "Pode aguardar que um de nossos consultores entrará em contato através de ligação ou WhatsApp no número (11) 4040-5686."
          );
          if(landing === "santander" || landing === "canopus" || landing === "porto" || landing === "chevrolet" || landing === "volkswagen"){
            const tagManagerArgs = {
              gtmId: 'GTM-WB757J7'
            }
          TagManager.initialize(tagManagerArgs);
          (window as any).dataLayer.push({
            event: "solicitacao-proposta",
            email: email,
            phone: tel
          });  
          }          
        setLoading(false);
          return setHasSuccess(true);
        });
    } catch (e: any) {
      setLoading(false);
      setErrorText("Houve um erro ao enviar seus dados.");
      return setHasError(true);
    }
  }

  function handleSuccess(s: any) {
    setHasSuccess(s);
    if (successLinkWP) {
      window.location.href = successLinkWP;

    } else {
      window.location.reload();
    }
  }

  useEffect(() => {

    handleSetTitle();
    handleGetStyles();
    handleGetForm();
    horaAcessado();
    handleGetIP();
    getNavigationData();
  }, []);

  useEffect(() => {
    console.log(extrato)
  }, [extrato])

  return estilo ? (
    <>
      <Helmet prioritizeSeoTags>
        <title>{tituloLanding}</title>
        <link rel="canonical" href={`https://concash.com.br/${landing}`} />
        <meta
          name="description"
          content={
            "Venda seu consórcio na Concash com a melhor negociação e receba seu dinheiro à vista, no prazo mais rápido do mercado. Solicite sua proposta."
          }
        />
      </Helmet>
      <V3Modal
        open={hasInfoCNP}
        actionClose={setHasInfoCNP}
        title={"Informação sobre seu consórcio"}
        estilo="cnp"
        html={
          <V3Text5 style={{ textAlign: "center" }}>
            <img src="https://concash-hml-files-pub.s3.us-east-2.amazonaws.com/677caixa_sr_marco_3+(1).png"></img>
            <br />
            Você pode obter <b>grupo e cota</b> diretamente no seu extrato, no
            canto superior esquerdo!
            <br />
            <a href="https://arealogada.cnpconsorcio.com.br/login">
              Clique aqui
            </a>{" "}
            e recupere seu extrato na área logada.
          </V3Text5>
        }
      />
      <V3Modal
          open={hasInfoSantander}
          height={350}
          estilo="santander"
          actionClose={setHasInfoSantander}
          title={"Encontre o número do seu contrato:"}
          html={
            <V3Text5 style={{ textAlign: "center", color: v3Colors.black }}>
              O número do contrato pode ser encontrado no canto superior direito
              do extrato detalhado da sua cota. Segue abaixo:
              <br />
              <br />
              <img loading="lazy" src={ContratoSantander} alt="sant" />
            </V3Text5>
          }
        />

      <V3Modal
        open={hasError}
        actionClose={setHasError}
        title={"Atenção!"}
        estilo={landing}
        text={errorText}
        type="error"
      />
      <V3Modal
        open={hasSuccess}
        actionClose={handleSuccess}
        title={"Seus dados foram enviados com sucesso!"}
        text={successText}
        textClose="OK"
        colorIconOK={estilo.backgroundColor}
        type="success"
        estilo={landing}
      />

      {/* Mobile */}
      {mobile && (
        <>
          {loading && (
            <Backdrop
              open={loading}
              style={{ display: "flex", maxWidth: "fit-content", zIndex: 999 }}
            >
              <Lottie animationData={iconLoading} width={"20%"} />
            </Backdrop>
          )}
          {cookies && (
            <Backdrop
              style={{ zIndex: 1000 }}
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={cookies}
            >
              <S.CookieBoxMobile
                style={{
                  maxWidth: "fit-content",
                }}
              >
                <S.TextFooterMobile>
                  Nós armazenamos dados temporariamente para melhorar a sua
                  experiência de navegação e recomendar conteúdo do seu
                  interesse. Ao continuar navegando, você concorda com a nossa
                  política de privacidade.
                </S.TextFooterMobile>

                <S.ButtonCookie
                  style={{
                    backgroundColor: estilo?.backgroundColor,
                    marginBottom: 10,
                  }}
                  onClick={() => setCookies(false)}
                >
                  CONTINUAR
                </S.ButtonCookie>
              </S.CookieBoxMobile>
            </Backdrop>
          )}
          <>
            <S.MobilePageContainer>
              <S.MobileHeader
                style={{ backgroundColor: estilo?.backgroundColor }}
              >
                <S.MobileHeaderTopBox>
                  <img
                    loading="lazy"
                    src={estilo?.adminLogo}
                    width={"152px"}
                    alt="adm"
                  />
                  <img
                    loading="lazy"
                    src={Plus}
                    width="15px"
                    height={"15px"}
                    alt="plus"
                  />
                  <img loading="lazy" src={ConcashMobile} alt="concash" />
                </S.MobileHeaderTopBox>
                <S.MobileFilterPhoto
                  style={{ backgroundColor: estilo?.backgroundColor }}
                />
                <S.MobilePhotoBox style={coverMobileStyle}>
                    <img
                        src={ landing === "santander" ? SImage : estilo?.backgroundCover }
                        alt={ estilo?.altBackground }
                        height="430px"
                        style={{
                            width: "100%",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                            backgroundSize: "cover",
                            borderRadius: "180px 180px 0px 0px",
                            color: "#FFF",
                            objectFit: "cover",
                        }}
                    />
                  {parametersTela[landing] == 22 && <S.GradientBox />}
                  <>
                    {landing === "santander" ? (
                      <S.MobilePhotoTitle
                        style={{
                          zIndex: 5,
                          fontSize: 22,
                          marginBottom: 10,
                          width: "90vw",
                          left: 10,
                        }}
                      >
                        Venda seu consórcio Santander de forma fácil, rápida e
                        com a melhor oferta do mercado
                      </S.MobilePhotoTitle>
                    ) : (
                      <>
                       {landing !== "cnp" ? (
                         <S.MobilePhotoTitle style={{ zIndex: 5 }}>A melhor proposta de venda da sua cota de consórcio!</S.MobilePhotoTitle>
                       ) : (
                         <S.MobilePhotoTitleCNP style={{ zIndex: 5 }}>Venda seu consórcio CNP com a melhor oferta do mercado!</S.MobilePhotoTitleCNP>
                       )}
                       </>
                    )}
                  </>

                  {landing === "cnp" ? (
                    <>

                      <S.MobilePhotoButton
                        onClick={() => (window.location.href = "#form")}
                        style={{
                          backgroundColor: estilo?.buttonColor,
                          color: estilo?.buttonTextColor,
                          zIndex: 7,
                          marginRight: "50%",
                        }}
                      >
                        QUERO VENDER
                      </S.MobilePhotoButton>
                      {landing === "cnp" && (
                      <S.MobilePhotoButton
                        onClick={() =>
                          window.open(
                            "https://cliente.hml.concash.com.br/otp-cnp",
                            "_blank"
                          )
                        }
                        style={{
                          backgroundColor: estilo?.buttonColor,
                          color: estilo?.buttonTextColor,
                          zIndex: 7,
                          marginLeft: "50%",
                        }}
                      >
                        CONSULTAR PROPOSTAS
                      </S.MobilePhotoButton>
                      )}
                    </>
                  ) : (
                    <S.MobilePhotoButton
                      onClick={() =>
                        landing !== "santander" ? window.location.href = "/venderconsorcio" : window.location.href = "#form"
                      }
                      style={{
                        backgroundColor: estilo?.buttonColor,
                        color: "#FFF",
                        zIndex: 7,
                      }}
                    >
                      QUERO VENDER
                    </S.MobilePhotoButton>
                  )}
                </S.MobilePhotoBox>
              </S.MobileHeader>
            </S.MobilePageContainer>

            <S.MobilePageContent>
              <S.MobileComoFunciona>
                {landing === "santander" ? (
                  <>
                    <S.MobileCFBoxTitleCol>
                      <S.MobileCFTitle
                        style={{ color: estilo?.backgroundColor }}
                      >
                        Como vender o seu consórcio Santander
                      </S.MobileCFTitle>
                      <S.MobileSubTitle>
                      Confira o passo a passo para vender seu consórcio com a Concash de forma simples, segura e 100% digital.
                      </S.MobileSubTitle>
                    </S.MobileCFBoxTitleCol>
                  </>
                ) : (
                  <S.MobileCFBoxTitle>
                    <S.DesktopCFTitle
                      style={{ color: estilo?.backgroundColor }}
                    >
                      Veja como funciona: é 100% digital, rápido e seguro
                    </S.DesktopCFTitle>
                  </S.MobileCFBoxTitle>
                )}

                <S.MobileCFBoxCards>
                  <ComoFuncionaCardMobile
                    estilo={estilo}
                    icon={iconP[landing][1]}
                    title="1. Preencha o formulário"
                    content={
                      landing === "santander"
                        ? "Você envia as informações do seu consórcio através de um formulário aqui no site e receba a melhor proposta para a venda do seu consórcio Santander"
                        : "Você envia as informações do seu consórcio através de um formulário aqui no site."
                    }
                    adm={landing}
                    alt="ícone de preenchimento de formulário"
                  />
                  <ComoFuncionaCardMobile
                    estilo={estilo}
                    icon={iconP[landing][2]}
                    title="2. Envie a documentação"
                    content="Envie a documentação e assine o contrato. Todo processo é 100% digital e com todo suporte da nossa equipe de especialistas."
                    adm={landing}
                    alt="ícone de envio de documentação"
                  />
                  <ComoFuncionaCardMobile
                    estilo={estilo}
                    icon={iconP[landing][3]}
                    title="3. Dinheiro nas suas mãos"
                    content="Com a assinatura do contrato, basta aguardar a transferência de titularidade e o dinheiro é depositado direto na sua conta."
                    adm={landing}
                    alt="ícone de dinheiro nas suas mãos"
                  />
                </S.MobileCFBoxCards>
              </S.MobileComoFunciona>

              {landing === "cnp" || landing === "santander" ? (
                <>
                  <div
                    id="form"
                    style={{
                      display: "flex",
                      flex: 1,
                      flexDirection: "column",
                      gap: 15,
                      margin: "15px",
                      padding: "15px",
                      alignContent: "center",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <h2
                      className="title-vantagens-mobile"
                      style={{
                        display: "flex",
                        marginBottom: 20,
                        color: landing === "cnp" ? "#002364" :  estilo.backgroundColor,
                        wordBreak: "break-word",
                        width: 300,
                      }}
                    >
                      {formProps?.formName}
                    </h2>
                    <>
                      <div>
                        <V3TextCustom
                          id={formProps?.fields[0]?.name}
                          sx={{ width: "320px" }}
                          size="small"
                          onChange={handleNomeRazao}
                          label={formProps?.fields[0]?.name}
                          error={nomeRazaoerr}
                          helperText={
                            nomeRazaoerr &&
                            `Preencha ${formProps?.fields[0]?.name}`
                          }
                          required
                          value={nomeRazao}
                        />
                      </div>
                      <V3TextCustom
                        id={formProps?.fields[3]?.name}
                        sx={{ width: 320 }}
                        size="small"
                        onChange={(e: any) => handleCpfCnpj(e.target.value)}
                        label={formProps?.fields[3]?.name}
                        error={cpfCnpjerr}
                        helperText={
                          cpfCnpjerr &&
                          `Preencha corretamente ${formProps?.fields[3]?.name}`
                        }
                        required
                        value={cpfCnpj}
                      />
                      <V3TextCustom
                        id={formProps?.fields[1]?.name}
                        sx={{ width: 320 }}
                        size="small"
                        onChange={(e: any) => handleTelefone(e.target.value)}
                        label={formProps?.fields[1]?.name}
                        error={telefoneerr}
                        helperText={
                          telefoneerr &&
                          `Preencha ${formProps?.fields[1]?.name}`
                        }
                        required
                        value={telefone}
                      />

                      {telefone && (
                        <div
                          style={{
                            display: "flex",
                            flex: 1,
                            alignItems: "flex-start",
                          }}
                        >
                          <FormControlLabel
                            value="sim"
                            style={{ display: "flex", alignSelf: "left" }}
                            control={
                              <Checkbox
                                checked={contatoWhatsapp}
                                style={{
                                  display: "flex",
                                  flex: 1,
                                  justifyContent: "flex-start",
                                }}
                                onChange={() =>
                                  setContatoWhatsapp(!contatoWhatsapp)
                                }
                                value={0}
                                name="radio-buttons"
                                inputProps={{ "aria-label": "A" }}
                                sx={{
                                  color: estilo.buttonColor,
                                  "&.Mui-checked": {
                                    color: estilo.buttonColor,
                                  },
                                }}
                              />
                            }
                            label={formProps?.fields[2]?.name}
                          />
                        </div>
                      )}
                      <V3TextCustom
                        id={formProps?.fields[4]?.name}
                        sx={{ width: 320 }}
                        size="small"
                        onChange={(e: any) => handleEmail(e.target.value)}
                        label={formProps?.fields[4]?.name}
                        error={emailerr}
                        helperText={
                          emailerr && `Preencha ${formProps?.fields[4]?.name}`
                        }
                        required
                        value={email}
                      />

                    {landing === "santander" && (
                      <>
                    <div className={StyleM.fontInter12} style={{ display: "flex", flexWrap: "wrap", maxWidth: "500px", alignItems: "center", justifyContent: "center"}}>
                      <span style={{ display: 'flex', textAlign: "center"}}>Informe abaixo o número do seu contrato, ou anexe o extrato detalhado da sua cota, para que possamos apresentar a melhor oferta para você</span>
                    </div>
                      <div
                        style={{
                          display: "flex",
                          flex: 1,
                          alignItems: "center",
                          justifyContent: "center",
                          gap: 10,
                        }}
                      >
                        <V3TextCustom
                          label="Informe o seu contrato"
                          style={{ width: 285 }}
                          onChange={(e) => {
                            const text = e.target.value.replace(/[^\d]/g, "");
                            if (text.length <= 20) {
                              setContrato(text);
                            }
                          }}
                          size="small"
                          value={contrato}
                          error={contratoerr}
                        />
                        <div style={{ position: "relative", right: "-10px" }}>
                        <ImInfo
                          onClick={() => setHasInfoSantander(true)}
                          style={{ cursor: "pointer" }}
                        />
                      </div>

                      </div>
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', fontFamily: "Inter, sans-serif", fontSize: 12}}>
                        <span>OU</span>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flex: 1,
                          alignItems: "center",
                          justifyContent: "center",
                          gap: 10,
                        }}
                      >
                        <V3FileInput width={290} label="Extrato" action={setExtrato} />
                      </div>
                      <div className={StyleM.fontInter12} style={{ display: "flex", flexWrap: "wrap", maxWidth: "100%", alignItems: "center", justifyContent: "center"}}>
                      <span>Ao enviar este formulário você concorda com as nossas</span><br />
                      <a
                        target="_blank"
                        href="https://concash-prod-files-pub.s3.sa-east-1.amazonaws.com/ConCash/Termos/Politica_Privacidade.pdf" rel="noreferrer"
                      >
                        políticas de privacidade
                      </a>
                    </div>
                      <V3Button
                        onClick={handleSendCNP}
                        estilo="santander"
                        width={300}
                        text="Enviar"
                      />
                    <div className={StyleM.fontInter12} style={{ fontStyle: "italic"}} >
                      <span style={{ textAlign: "left"}}>* Preenchimento Obrigatório</span>
                    </div>
                      </>
                    )}
                    {landing === "cnp" && (
                      <>
                      <div
                      style={{
                        display: "flex",
                        flex: 1,
                        alignItems: "center",
                        justifyContent: "center",
                        gap: 10,
                      }}
                    >
                      <V3TextCustom
                        label="Grupo"
                        style={{ minWidth: 125 }}
                        onChange={(e) => {
                          const text = e.target.value.replace(/[^\d]/g, "");
                          if (text.length <= 5) {
                            setGrupo(text);
                          }
                        }}
                        size="small"
                        value={grupo}
                        error={grupoerr}
                      />
                      <V3TextCustom
                        label="Cota"
                        style={{ minWidth: 125 }}
                        onChange={(e) => {
                          const text = e.target.value.replace(/[^\d]/g, "");
                          if (text.length <= 5) {
                            setCota(text);
                          }
                        }}
                        size="small"
                        value={cota}
                        error={cotaerr}
                      />

                      <div style={{ position: "relative", right: "-6px" }}>
                        <ImInfo
                          onClick={() => setHasInfoCNP(true)}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    </div>
                    <div className={StyleM.fontInter12}>
                      Ao enviar este formulário você concorda com as nossas{" "}
                      <a
                        target="_blank"
                        href="https://concash-prod-files-pub.s3.sa-east-1.amazonaws.com/ConCash/Termos/Politica_Privacidade.pdf" rel="noreferrer"
                      >
                        políticas de privacidade
                      </a>
                    </div>
                      <V3Button
                        onClick={handleSendCNP}
                        estilo="cnp"
                        width={300}
                        text="Enviar"
                      />
                      <div className={StyleM.fontInter12}  style={{ fontStyle: "italic"}}>
                      <span style={{ textAlign: "left"}}>* Preenchimento Obrigatório</span>
                    </div>
                      </>
                    )}
                    </>
                  </div>
                </>
              ) : (
                <S.ButtonVenderMobile
                  href={landing === "cnp" || landing === "santander" ? "#form" : "/venderconsorcio"}
                  style={{
                    background: estilo?.backgroundColor,
                    color: estilo?.buttonTextColor,
                    width: "85%",
                    minHeight: 45,
                    textDecoration: "none",
                    cursor: "pointer",
                    margin: "0 30px",
                  }}
                >
                  Quero Vender
                </S.ButtonVenderMobile>
              )}

              <VantagensMobile
                title={ landing === "cnp" ? "Vantagens de vender a sua cota CNP com a Concash" : "Vantagens de vender a sua cota com a Concash"}
                estilo={estilo}
                adm={landing}
                title1={ "Proposta em até 24h" }
                text1={ "Envie os seus dados e receba uma proposta em até 24 horas." }
                alt1={ "ícone de proposta rápida" }
                title2={ "Pagamento à vista" }
                text2={ "Receba o dinheiro direto na sua conta, em poucos dias úteis." }
                alt2={ "ícone de pagamento à vista" }
                title3={ "Atendimento" }
                text3={ "Nós conduzimos o processo de venda desde a aprovação ao pagamento final da cota." }
                alt3={ "ícone de atendimento" }
              />
            </S.MobilePageContent>
          </>

          <FooterMobile landing={landing} estilo={estilo} />
        </>
      )}

      {/* Desktop */}
      {!mobile && (
        <>
          {loading && (
            <Backdrop open={loading} style={{ zIndex: 999 }}>
              <Lottie animationData={iconLoading} width={"40%"} />
            </Backdrop>
          )}
          <>
            {cookies && (
              <Backdrop style={{ zIndex: 999 }} open={cookies}>
                <S.CookieBoxDesktop>
                  <S.TextFooterDesktop>
                    Nós armazenamos dados temporariamente para melhorar a sua
                    experiência de navegação e recomendar conteúdo do seu
                    interesse. Ao continuar navegando, você concorda com a nossa
                    política de privacidade.
                  </S.TextFooterDesktop>

                  <S.ButtonCookie
                    style={{ backgroundColor: estilo?.backgroundColor }}
                    onClick={() => setCookies(false)}
                  >
                    CONTINUAR
                  </S.ButtonCookie>
                </S.CookieBoxDesktop>
              </Backdrop>
            )}
            <S.DesktopPageContainer>
              <S.DesktopHeader
                style={{ backgroundColor: estilo?.backgroundColor }}
              >
                <S.DesktopBoxLogo>
                  <img
                    loading="lazy"
                    src={estilo?.adminLogo}
                    width={"152px"}
                    height={"48px"}
                    alt="adm"
                  />
                  <img
                    loading="lazy"
                    src={Plus}
                    width="15px"
                    height={"15px"}
                    alt="plus"
                  />
                  <img
                    loading="lazy"
                    src={ConcashMobile}
                    height={"40px"}
                    alt="concash"
                  />
                </S.DesktopBoxLogo>
                <S.DesktopBoxTitle>
                  {landing === "santander" ? (
                    <S.DesktopHeaderTitle>
                      Venda seu consórcio Santander de forma fácil, rápida e com
                      a melhor oferta do mercado
                    </S.DesktopHeaderTitle>
                  ) : (
                    <S.DesktopHeaderTitle>
                       {landing === "cnp" ? "Venda seu consórcio CNP com a melhor oferta do mercado" : "A melhor proposta de venda da sua cota de consórcio!"}
                    </S.DesktopHeaderTitle>
                  )}
                </S.DesktopBoxTitle>

                <S.DesktopBoxButton>
                  {landing === "cnp" ? (
                    <>
                    <S.DesktopBoxButtonCNP>
                    <S.DesktopHeaderButton
                      onClick={() =>
                        landing === "cnp" || landing === "santander"
                        ? (window.location.href = "#form")
                        : (window.location.href = "/venderconsorcio")
                      }
                      >
                      QUERO VENDER
                    </S.DesktopHeaderButton>

                        <S.DesktopHeaderButton
                          onClick={() =>
                            window.open(
                              "https://cliente.hml.concash.com.br/otp-cnp",
                              "_blank"
                            )
                          }
                        >
                          ACOMPANHAR PROPOSTA
                        </S.DesktopHeaderButton>
                      </S.DesktopBoxButtonCNP>
                    </>
                  ) : (
                    <S.DesktopBoxButton>

                    <S.DesktopHeaderButton
                    onClick={() =>
                      landing === "cnp" || landing === "santander"
                      ? (window.location.href = "#form")
                      : (window.location.href = "/venderconsorcio")
                    }
                    >
                    QUERO VENDER
                  </S.DesktopHeaderButton>
                    </S.DesktopBoxButton>
                  )}
                </S.DesktopBoxButton>
                
                <S.DesktopPhotoBox style={coverDesktopStyle}>
                    <img
                        src={ landing === "santander" ? SImage : estilo?.backgroundCover }
                        alt={ estilo?.altBackground }
                        style={{
                            display: "flex",
                            borderRadius: "190px 190px 0px 0px",
                            backgroundSize: "cover",
                            position: "absolute",
                            width: "400px",
                            height: "344px",
                            objectFit: "cover",
                        }}
                    />
                </S.DesktopPhotoBox>

                <img
                  loading="lazy"
                  src={Tip1}
                  style={{ position: "absolute", top: 0, right: 130, width: 116, zIndex: 0, }}
                  alt="tip1"
                />

                <img
                  loading="lazy"
                  src={Tip2}
                  style={{ position: "absolute", top: 263, right: 623, width: 116, zIndex: 0, }}
                  alt="tip2"
                />
              </S.DesktopHeader>
            </S.DesktopPageContainer>
            <S.DesktopPageContent>
              <S.DesktopComoFunciona>
                {landing === "santander" ? (
                  <>
                    <S.DesktopCFBoxTitleCol>
                      <S.DesktopCFTitle style={{ color: estilo?.backgroundColor }} >
                        Como vender o seu consórcio Santander
                      </S.DesktopCFTitle>
                      <S.DesktopSubTitle>
                        Confira o passo a passo para vender seu consórcio com a Concash de forma simples, segura e 100% digital.
                      </S.DesktopSubTitle>
                    </S.DesktopCFBoxTitleCol>
                  </>
                ) : (
                  <S.DesktopCFBoxTitle>
                    <S.DesktopCFTitle
                      style={{ color: estilo?.backgroundColor }}
                    >
                      Veja como funciona: é 100% digital, rápido e seguro
                    </S.DesktopCFTitle>
                  </S.DesktopCFBoxTitle>
                )}
                <S.DesktopCFBoxCards>
                  <ComoFuncionaCardDesktop
                    estilo={estilo}
                    icon={iconP[landing][1]}
                    title="1. Preencha o formulário"
                    content={
                      landing === "santander"
                        ? "Você envia as informações do seu consórcio através de um formulário aqui no site e receba a melhor proposta para a venda do seu consórcio Santander"
                        : "Você envia as informações do seu consórcio através de um formulário aqui no site."
                    }
                    adm={landing}
                    alt="ícone de preenchimento de formulário"
                  />
                  <ComoFuncionaCardDesktop
                    estilo={estilo}
                    icon={iconP[landing][2]}
                    title="2. Envie a documentação"
                    content="Envie a documentação e assine o contrato. Todo processo é 100% digital e com todo suporte da nossa equipe de especialistas."
                    adm={landing}
                    alt="ícone de envio de documentação"
                  />
                  <ComoFuncionaCardDesktop
                    estilo={estilo}
                    icon={iconP[landing][3]}
                    title="3. Dinheiro nas suas mãos"
                    content="Com a assinatura do contrato, basta aguardar a transferência de titularidade e o dinheiro é depositado direto na sua conta."
                    adm={landing}
                    alt="ícone de dinheiro nas suas mãos"
                  />
                </S.DesktopCFBoxCards>
              </S.DesktopComoFunciona>
            </S.DesktopPageContent>

            {landing === "cnp" || landing === "santander" ? (
              <>
                <div
                  id="form"
                  style={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "column",
                    gap: 15,
                    margin: "20px 200px 50px 200px",
                    padding: "0 100px",
                    alignContent: "center",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <h2 className="title-vantagens" style={{ marginBottom: 20, color: landing === "cnp" ? "#002364" : "#000"  }}>
                    {formProps?.formName} 
                  </h2>
                  <>
                    <div>
                      <V3TextCustom
                        id={formProps?.fields[0]?.name}
                        sx={{ width: 500 }}
                        size="small"
                        onChange={handleNomeRazao}
                        label={formProps?.fields[0]?.name}
                        error={nomeRazaoerr}
                        helperText={
                          nomeRazaoerr &&
                          `Preencha ${formProps?.fields[0]?.name}`
                        }
                        required
                        value={nomeRazao}
                      />
                    </div>
                    <V3TextCustom
                      id={formProps?.fields[3]?.name}
                      sx={{ width: 500 }}
                      size="small"
                      onChange={(e: any) => handleCpfCnpj(e.target.value)}
                      label={formProps?.fields[3]?.name}
                      error={cpfCnpjerr}
                      helperText={
                        cpfCnpjerr &&
                        `Preencha corretamente ${formProps?.fields[3]?.name}`
                      }
                      required
                      value={cpfCnpj}
                    />
                    <div style={{ display: "flex", gap: 10 }}>
                      <V3TextCustom
                        id={formProps?.fields[1]?.name}
                        sx={{ minWidth: 250 }}
                        size="small"
                        onChange={(e: any) => handleTelefone(e.target.value)}
                        label={formProps?.fields[1]?.name}
                        error={telefoneerr}
                        helperText={
                          telefoneerr &&
                          `Preencha ${formProps?.fields[1]?.name}`
                        }
                        required
                        value={telefone}
                      />
                      <FormControlLabel
                        value="sim"
                        style={{
                          display: "flex",
                          alignSelf: "left",
                          minWidth: 220,
                        }}
                        control={
                          <Checkbox
                            checked={contatoWhatsapp}
                            style={{
                              display: "flex",
                              flex: 1,
                              justifyContent: "flex-start",
                            }}
                            onChange={() =>
                              setContatoWhatsapp(!contatoWhatsapp)
                            }
                            value={0}
                            name="radio-buttons"
                            inputProps={{ "aria-label": "A" }}
                            sx={{
                              color: estilo.buttonColor,
                              "&.Mui-checked": {
                                color: estilo.buttonColor,
                              },
                            }}
                          />
                        }
                        label={formProps?.fields[2]?.name}
                      />
                    </div>

                    <V3TextCustom
                      id={formProps?.fields[4]?.name}
                      sx={{ width: 500 }}
                      size="small"
                      onChange={(e: any) => handleEmail(e.target.value)}
                      label={formProps?.fields[4]?.name}
                      error={emailerr}
                      helperText={
                        emailerr && `Preencha ${formProps?.fields[4]?.name}`
                      }
                      required
                      value={email}
                    />



                    {landing === "santander" && (
                      <>
                      <div className={StyleM.fontInter12}>
                        <span style={{ display: 'flex', textAlign: 'center', maxWidth: 500}}>Informe abaixo o número do seu contrato, ou anexe o extrato detalhado da sua cota, para que possamos apresentar a melhor oferta para você</span>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flex: 1,
                          alignItems: "center",
                          justifyContent: "center",
                          gap: 10,
                          margin: "0 210px",
                          paddingLeft: 22
                        }}
                      >
                        <V3TextCustom
                          label="Informe o seu contrato"
                          style={{ width: 500 }}
                          onChange={(e) => {
                            const text = e.target.value.replace(/[^\d]/g, "");
                            if (text.length <= 20) {
                              setContrato(text);
                            }
                          }}
                          size="small"
                          value={contrato}
                          error={contratoerr}
                        />
                        <div style={{ position: "relative", right: "-10px" }}>
                        <ImInfo
                          onClick={() => setHasInfoSantander(true)}
                          style={{ cursor: "pointer" }}
                        />
                      </div>

                      </div>
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', fontFamily: "Inter, sans-serif", fontSize: 12}}>
                        <span>OU</span>
                      </div>
                          <div style={{position: "relative", left: "-3px", marginTop: 5 }}>
                          <V3FileInput width={480} label="Extrato" action={setExtrato} />
                          </div>
                      <div className={StyleM.fontInter12} style={{ marginTop: 15, marginBottom: 15 }}>
                      Ao enviar este formulário você concorda com as nossas{" "}
                      <a
                        target="_blank"
                        href="https://concash-prod-files-pub.s3.sa-east-1.amazonaws.com/ConCash/Termos/Politica_Privacidade.pdf" rel="noreferrer"
                      >
                        políticas de privacidade
                      </a>
                    </div>
                      <V3Button
                        onClick={handleSendCNP}
                        estilo="santander"
                        width={500}
                        text="Enviar"
                      />
                      <div className={StyleM.fontInter12} style={{ fontStyle: "italic"}}>
                          * Preenchimento Obrigatório
                        </div>
                      </>
                    )}
                    {landing === "cnp" && (
                      <>
                      <div
                      style={{
                        display: "flex",
                        flex: 1,
                        alignItems: "center",
                        justifyContent: "center",
                        gap: 10,
                        margin: "0 210px",
                        paddingLeft: 22,
                      }}
                    >
                      <V3TextCustom
                        label="Grupo"
                        style={{ minWidth: 245 }}
                        onChange={(e) => {
                          const text = e.target.value.replace(/[^\d]/g, "");
                          if (text.length <= 5) {
                            setGrupo(text);
                          }
                        }}
                        size="small"
                        value={grupo}
                        error={grupoerr}
                      />
                      <V3TextCustom
                        label="Cota"
                        style={{ minWidth: 245 }}
                        onChange={(e) => {
                          const text = e.target.value.replace(/[^\d]/g, "");
                          if (text.length <= 5) {
                            setCota(text);
                          }
                        }}
                        size="small"
                        value={cota}
                        error={cotaerr}
                      />

                      <div style={{ position: "relative", right: "-10px" }}>
                        <ImInfo
                          onClick={() => setHasInfoCNP(true)}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    </div>
                    <div className={StyleM.fontInter12}>
                      Ao enviar este formulário você concorda com as nossas{" "}
                      <a
                        target="_blank"
                        href="https://concash-prod-files-pub.s3.sa-east-1.amazonaws.com/ConCash/Termos/Politica_Privacidade.pdf" rel="noreferrer"
                      >
                        políticas de privacidade
                      </a>
                    </div>
                      <V3Button
                        onClick={handleSendCNP}
                        estilo="cnp"
                        width={500}
                        text="Enviar"
                      />
                        <div className={StyleM.fontInter12} style={{ fontStyle: "italic"}}>
                          * Preenchimento Obrigatório
                        </div>
                      </>
                    )}
                  </>
                </div>
              </>
            ) : (
              <div
                className="box-button"
                style={{
                  display: "flex",
                  alignContent: "center",
                  justifyContent: "center",
                }}
              >
                <S.ButtonVender
                  href={landing === "cnp" || landing === "santander" ? "#form" : "/venderconsorcio"}
                  style={{
                    background: estilo?.backgroundColor,
                    color: estilo?.buttonTextColor,
                    maxWidth: 450,
                    maxHeight: 45,
                    textDecoration: "none",
                    cursor: "pointer",
                  }}
                >
                  Quero Vender
                </S.ButtonVender>
              </div>
            )}

            <VantagensDesktop
              title={ landing === "cnp" ? "Vantagens de vender a sua cota CNP com a Concash" : "Vantagens de vender a sua cota com a Concash"}
              estilo={estilo}
              adm={landing}
              title1={ "Proposta em até 24h" }
              text1={ "Envie os seus dados e receba uma proposta em até 24 horas." }
              alt1={ "ícone de proposta rápida" }
              title2={ "Pagamento à vista" }
              text2={ "Receba o dinheiro direto na sua conta, em poucos dias úteis." }
              alt2={ "ícone de pagamento à vista" }
              title3={ "Atendimento" }
              text3={ "Nós conduzimos o processo de venda desde a aprovação ao pagamento final da cota." }
              alt3={ "ícone de atendimento" }
            />
          </>

          <FooterDesktop landing={landing} estilo={estilo} />
        </>
      )}
    </>
  ) : (
    <></>
  );
};

export default Home;